<template>
    <div
        class="card relative overflow-hidden flex flex-col gap-1 flex-1"
        :class="classes"
    >
        <div class="relative border-2 border-[#eaeaea] overflow-hidden group flex flex-col mb-2">
            <fav-heart
                entry-id="1"
                user-id="1"
                class="absolute z-10 top-2 right-2"
            />
            <a :href="permalink">
                <img
                    loading="lazy"
                    v-if="image.url && hasThumbnail"
                    class="w-full card-thumb object-cover"
                    :alt="title"
                    :src="image.url"
                />
            </a>
            <div
            v-if="any_specs_set"
                class="card-body leading-relaxed absolute bottom-0 z-10 w-full text-white bg-primary bg-opacity-90 group-hover:translate-y-0 transition-transform transform translate-y-full p-6"
            >
                <ul class="m-0 font-light leading-normal font-bold">
                    <li v-if="number_of_users">
                        Number of Users: {{ number_of_users }}
                    </li>
                    <li v-if="age_range">Age Range: {{ age_range }}</li>
                    <li v-if="activities">Activities: {{ activities }}</li>
                    <li v-if="inclusiveness">
                        <span class="text-secondary">Inclusive</span>
                    </li>
                </ul>
            </div>
            <img
                class="absolute top-1 left-1 w-8 h-8 z-10"
                v-if="show_inclusive_icon"
                :src="inclusive_icon_url"
                alt="this product is inclusive"
            />
        </div>
        <div v-if="product_code" class="text-sm">
            <p v-text="product_code" class="text-textmain m-0 relative" />
        </div>
        <h4 class="text-primary leading-tight text-xl m-0">
            <a
                class="text-textmain no-underline hover:text-textmain relative"
                :href="permalink"
            >
                {{ title }}
            </a>
        </h4>
    </div>
</template>

<script setup>
import { cardProps, useCard } from "../../composables/useCard";
import { ref, computed } from "vue";

const { entry, thumbnail, hasThumbnail, isShareable, actions, titleIcon } =
    defineProps({
        ...cardProps,
        actions: Array,
        titleIcon: {
            type: String,
            default: "chevron-right",
        },
    });

const { title, content, image, permalink } = useCard(entry, thumbnail);

const product_code = entry?.product_code || false;
const number_of_users = entry?.number_of_users || false;

const age_range =
    entry?.age_range?.map((item) => item.title).pop() || false;
const play_type = entry?.play_type?.length || false;
const activities = entry?.activities || false;
const show_inclusive_icon = entry?.inclusive_icon || false;
const inclusiveness = (show_inclusive_icon && entry?.inclusiveness?.length) || false;

// Check if none of number_of_users, age_range, play_type, inclusiveness are set.
const any_specs_set =
    number_of_users || age_range || play_type || inclusiveness;

const inclusive_icon_url = show_inclusive_icon
    ? "/assets/icons/inclusive_no_text.svg"
    : false;

const tempToggle = ref(false);

const isFavourite = computed({
    get: () => {
        return tempToggle.value;
    },
    set: (value) => {
        return (tempToggle.value = value);
    },
});
</script>

<style></style>
