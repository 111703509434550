<template>
    <div class="buildamic-row gap-10 w-full">
        <div class="buildamic-column col col-9">
            <template v-if="showResults && results">
                <v-filter-results
                    :card="card"
                    :columns="columns"
                />
                <v-loadmore
                    v-if="pagination"
                    :key="pagination.current_page"
                    :pagination="pagination"
                    :loading="loading"
                    @loadmore="loadMore"
                />
            </template>
        </div>
        <div class="col buildamic-column col-3 w-full">
            <div class="sticky top-[var(--product-cat-header-height)]" v-if="filters?.length" data-istoggle>
                <v-filter-controls
                    :class="[filterControlsClass]"
                    :filter-control-class="filterControlClass"
                    :base-filters="baseFilters"
                    :exclude="exclude"
                    :filters="filters"
                    :collection="collection"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { toRefs, nextTick, computed, provide, onMounted } from "vue";
import { results, url, useFilter } from "../../composables/useFilter";
import { contentAPI } from "../../services/contentAPI";

import VIcon from "../VIcon.vue";
export default {
    components: { VIcon },
    props: {
        filters: Object,
        filterTitle: String,
        card: String,
        columns: String,
        collection: String,
        exclude: Array,
        filterControlsClass: {
            type: String,
            default: "flex flex-col lg:gap-10",
        },
        filterControlClass: {
            type: String,
            default: "",
        },
        responsiveControls: {
            type: Boolean,
            default: true,
        },
        showResults: {
            type: Boolean,
            default: true,
        },
        baseFilters: {
            type: Object,
            default: {},
        },
    },
    setup(props) {
        const {
            collection,
            card,
            filterTitle,
            columns,
            baseFilters,
            showResults,
            exclude,
            responsiveControls,
        } = props;
        const { filters } = toRefs(props);

        const pagination = computed(() => {
            return results.meta;
        });

        const { fetchResults, fetchMore, loading } = useFilter(contentAPI, {
            collection,
        });

        provide("responsiveControls", responsiveControls);

        const pageChange = async () => {
            await nextTick();
            fetchResults();
        };

        const loadMore = async (page) => {
            await nextTick();
            fetchMore(page);
        };

        onMounted(() => {
            fetchResults();
        })

        return {
            loading,
            filterTitle,
            filters,
            collection,
            columns,
            card,
            pagination,
            pageChange,
            loadMore,
            results,
            exclude,
            showResults,
            baseFilters,
        };
    },
};
</script>

<style></style>
