
import { computed } from "vue";
import { truncateWords } from "../functions/helpers";

const cardProps = {
  entry: Object,
  thumbnail: {
    type: String,
    default: "",
  },
  hasThumbnail: {
    type: Boolean,
    default: false,
  },
  isShareable: {
    type: Boolean,
  },
  showCategory: {
    type: Boolean
  },
  classes: {
    type: String,
    default: "bg-white",
  },
}

const useCard = (entry, thumbnail, options = { image_params: "w=1000&h=660&fit=contain" }) => {
  console.log({ entry })
    const title = entry?.title ? truncateWords(entry.title, 16, "...") : "";
    const content = entry?.excerpt
        ? truncateWords(entry.excerpt, 30, "...")
        : entry?.content
            ? entry.content
            : "";
    const ID = entry?.id || "";
    const permalink = entry?.permalink || "";
    const imageURL = computed(() => {
        return (
            thumbnail ||
            (entry?.thumbnail &&
                `/img/${entry?.thumbnail?.id}?${options.image_params}`)
        );
    });

    const imageALT = computed(() => {
        return entry?.thumbnail ? entry?.thumbnail?.alt : "";
    });

    const image = computed(() => {
        return { url: imageURL.value, alt: imageALT.value };
    })

    return {
        title,
        content,
        ID,
        permalink,
        image
    }
}

export { useCard, cardProps }
