
import { isAbove } from './functions/breakpoints';

export class Navbar {
    constructor(navbar, options = {}) {
        this.navbar = navbar;
        this.hasChildrenAnchors = [...this.navbar.querySelectorAll('.nav-item__has-children > a'), ...this.navbar.querySelectorAll('.nav-item__has-megamenu > a')]
        this.toggleSubMenu = this.toggleSubMenu.bind(this)
        this.mobileMenuActive = false;

        if (!isAbove.lg.matches) {
            this.addListeners();
        }

        isAbove.lg.addListener(e => {
            if (!e.matches) {
                this.addListeners();
            } else {
                this.removeListeners();
            }
        });

    }

    toggleSubMenu(e) {
        const target = e.target;
        if (this.hasChildrenAnchors.find(el => el.isEqualNode(target))) e.preventDefault();

        const parent = target.parentElement;
        const subMenu = parent.querySelector(':scope > .submenu') || parent.querySelector(':scope > .megamenu');

        if (subMenu) {
            parent.classList.toggle('is-active');
            subMenu.classList.toggle('active');
        }
    }

    addListeners(cb) {
        this.navbar.addEventListener('click', this.toggleSubMenu, true);
    }

    removeListeners() {
        this.navbar.removeEventListener('click', this.toggleSubMenu, true);
    }
}
