let observer

export default {
    beforeMount(el, binding, vnode) {
        const { target, onClass = 'opacity-100', offClass = 'opacity-0' } = binding.value

        observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    el.classList.add(onClass)
                    el.classList.remove(offClass)
                } else {
                    el.classList.add(offClass)
                    el.classList.remove(onClass)
                }
            })
        });

        observer.observe(document.querySelector(target));
    }
}

