<template lang="">
  <a @click="downloadFile" class="flex items-center gap-1" :href="download.url">
    <v-icon v-if="iconName" :svg-class="'w-12 h-14'" :name="iconName"></v-icon>
    <span v-if="download.title">{{ download.title }}</span>
  </a>
</template>
<script setup>
import { computed, toRefs } from 'vue'

  const props = defineProps({
    download: Object
  })

  const { download } = toRefs(props)

  const downloadFile = async (e) => {
    e.preventDefault();

    // Force download the file, even if it's an image, rather than opening in browser
    const a = document.createElement("a");
    a.href = await toDataURL(download.value.url);
    a.download = download.value?.path?.split('/').pop() || "download";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  const iconName = computed(() => {
    return download.value?.path?.split('.').pop() + '-icon'
  })

  async function toDataURL (url) {
    return fetch(url)
      .then((response) => {
        return response.blob();
      })
      .then((blob) => {
        return URL.createObjectURL(blob);
      });
  }
</script>
<style lang="">

</style>
