<template lang="">
  <div class="grid gap-6 list-collections" :class="[`list-collections__${collection}`, columns]">
    <component v-for="entry in entries" show-category has-thumbnail :key="entry.id" :is="card" :entry="entry"></component>
    <v-loading-spinner v-if="loading" />
  </div>
  <v-loadmore
    v-if="pagination"
    :key="pagination.current_page"
    :pagination="pagination"
    @loadmore="loadMore"
  />
</template>
<script setup>
import { ref, onMounted, computed, toRefs } from "vue";
import { useEntries } from "../composables/useEntries";

let params = new URLSearchParams(window.location.search)

const pagination = ref(null);

const props = defineProps({
  collection: String,
  card: {
    type: String,
    required: true
  },
  columns: {
    type: String,
    required: false,
    default: "lg:grid-cols-4 md:grid-cols-3 sm:grid-cols-2 grid-cols-1",
  },
  taxonomy: {
    type: String,
    required: false,
    default: "",
  },
  terms: {
    type: Array,
    required: false,
  },
  limit: {
    type: Number,
    required: false,
    default: 8,
  },
  loadmore: {
    type: Boolean,
    required: false,
    default: false,
  },
});

const entries = ref([]);

const { fetch } = useEntries(props?.collection);

const loading = ref(false);

const loadMore = async (page) => {
  params.set('page', page)

  loading.value = true;

  try {
    const res = await fetch(params.toString()); 

    entries.value = [...entries.value, ...res.data];

    pagination.value = res.meta;
  } catch (error) {
    console.log({error})
  }

  loading.value = false;
};

onMounted(async () => {
  if (props?.taxonomy) {
    // url[`taxonomies[${props?.taxonomy}]`] = `${props?.taxonomy}::${props?.terms.join(',')}`;
    params.set(`taxonomies[${props?.taxonomy}]`, `${props?.taxonomy}::${props?.terms.join(',')}`)
  }
  if (props?.limit) {
    // url['limit'] = props?.limit;
    params.set('limit', props?.limit)
  }
  
  loading.value = true;

  try {
    const res = await fetch(params.toString());

    entries.value = res.data;

    pagination.value = res.meta;
    
  } catch (error) {
    console.log({error})
  }

  loading.value = false;
});

</script>