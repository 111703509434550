<template>
    <v-icon
        v-for="i in count"
        :key="`cloud-${i}`"
        class="cloud cloud-speed-7 absolute opacity-9"
        :style="{
            left: `${randCount(1, 10) * 15}%`,
            top: `${randCount(1, 100)}%`,
            'font-size': `${randCount(4, 10)}rem`,
        }"
        :class="[`cloud-speed-${randCount(3, 10)}`]"
        name="cloud"
    />
</template>

<script>
export default {
    props: {
        count: {
            type: Number,
            default: 10,
        },
    },
    setup({ count }) {
        //random number between 1 and 12
        const randCount = (min = 1, max = 12) =>
            Math.floor(Math.random() * max) + min;
        return {
            count,
            randCount,
        };
    },
};
</script>

<style></style>
