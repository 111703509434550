<template>
    <div class="card card__case-study flex flex-col gap-3 relative flex-1">
        <template v-if="hasThumbnail">
            <div v-if="image?.url" class="card-image rounded overflow-hidden">
                <a :href="permalink">
                    <img
                        loading="lazy"
                        class="w-full object-cover transition-transform duration-500 hover:scale-110"
                        :alt="title"
                        :src="image.url"
                    />
                </a>
            </div>
        </template>
        <a
            :href="category[0].url"
            v-if="category && showCategory"
            class="font-bold text-copycolor"
            >{{ category[0].title }} {{ showCategory }}</a
        >
        <h4 class="card__title text-xl m-0 p-0">
            <a :href="permalink">{{ title }}</a>
        </h4>
        <div v-if="excerpt" class="card__content mb-2" v-html="excerpt" />
        <div class="flex card__buttons items-center gap-4">
            <a class="btn card-link block" :href="permalink"> Learn More </a>
            <social-share v-if="isShareable" :post="entry" />
        </div>
    </div>
</template>

<script setup>
import { truncateWords } from "../../functions/helpers";

import { cardProps, useCard } from "../../composables/useCard.js";

const { entry, thumbnail, hasThumbnail, isShareable, showCategory } = defineProps({
    ...cardProps,
});

const { title, image, permalink } = useCard(entry, thumbnail, {
    image_params: "w=680&h=500&fit=crop",
});

const category = entry?.category ? entry?.category : "";

const excerpt = truncateWords(
    entry?.excerpt
        ? entry.excerpt
        : typeof entry?.content === "object"
        ? entry.content.filter((item) => item.type === "text")?.[0]?.text
        : entry.content,
    20,
    "..."
)?.replace(/<\/?[^>]+(>|$)/g, "");
</script>
