export default {
  mounted(el, binding, vnode) {
    // binding in an array of selectors. We need to reduce all of those elements height into a single height, that we set the "top" of the element to
    const height = binding.value.reduce((acc, selector) => {
      let element = document.querySelector(selector)
      if (element) {
        acc += element.offsetHeight
      }
      return acc
    }, 0)

    // Set the height to the top property of the element
    el.style.top = `${height}px`
  }
}

