export default {
    dialog: null,

    clickOutsideEvent(e) {
        if (e.offsetX < 0 || e.offsetX > e.target.offsetWidth ||
            e.offsetY < 0 || e.offsetY > e.target.offsetHeight) {
            closeDialog();
        }
    },

    openDialog(e) {
        e.stopPropagation();
        e.preventDefault();

        if (this.dialog.dataset.hasOwnProperty('inline')) {
            this.dialog.show();
        } else {
            this.dialog.showModal();
        }
        this.dialog.addEventListener('click', clickOutsideEvent);
    },

    closeDialog() {
        this.dialog.close();
        this.dialog.removeEventListener('click', clickOutsideEvent);
    },

    mounted(el, { value, dir }) {
        dir.dialog = document.querySelector(value);
        dir.openDialog = dir.openDialog.bind(dir);
        dir.closeDialog = dir.closeDialog.bind(dir);

        if (!dir.dialog) return

        const closeBtn = document.createElement('i');
        closeBtn.classList.add('fas', 'fa-times', 'close-dialog');
        dir.dialog.appendChild(closeBtn);

        el.addEventListener('click', dir.openDialog, true);
        closeBtn.addEventListener('click', dir.closeDialog);

    },
    unmounted(el, { dir }) {
        el.removeEventListener('click', dir.openDialog, true);
        closeBtn.removeEventListener('click', dir.closeDialog);
    }
}
