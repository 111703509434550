<template>
    <GoogleMap
      ref="mapRef"
      api-key="AIzaSyDdSQR50QDiNlCyFr7M6U-ZniCe346KJ1U"
      :center="center"
      :zoom="7"
      mapId="b70a49b5957c1fea"
      class="project-map w-full relative text-blue-300"
      @click="handleMapClick"
    >
        <MarkerCluster :options="{ renderer: new ClusterRenderer() }">
            <Marker
                :key="m.id"
                v-for="(m, index) in markers"
                :options="m"
                @click="handleInfoWindow(m)"
            >
                <InfoWindow :key="m.id" v-model="m.active">
                    <component :init="m.active" :is="`info-${m.type}`" :m="m" />                    
                </InfoWindow>
            </Marker>
        </MarkerCluster>
        <v-filter-controls
            :header-icons="false"
            inline
            :filters="filters"
            :base-filters="baseFilters"
            class="flex max-w-[1080px] flex-col lg:flex-row p-6 lg:absolute lg:w-[70vw] shadow-lg bottom-10 left-0 right-0 mx-auto bg-white lg:items-center justify-center gap-8 map-filter-controls"
            filter-control-class="flex flex-col lg:flex-row"
            collection="projects"
            :responsive-controls="responsiveControls"
        ></v-filter-controls>
    </GoogleMap>
</template>

<script>
import { watch, ref, reactive, computed, nextTick } from "vue";
import { results } from "../../composables/useFilter";
import { truncateWords } from "../../functions/helpers";
import { GoogleMap, Marker, InfoWindow, MarkerCluster } from "vue3-google-map";
import { ClusterRenderer } from "../../classes/ClusterRenderer"
import debounce from "../../functions/debounce"


export default {
    name: "Map",
    components: {
        GoogleMap,
        Marker,
        InfoWindow,
        MarkerCluster
    },
    props: {
        filters: {
            type: Object,
        },
        baseFilters: {
            type: Object,
        },
        responsiveControls: {
            type: Boolean,
            default: true,
        },
    },
    setup({ filters, baseFilters, responsiveControls }) {
        const options = {
            mapId: "b70a49b5957c1fea",
        };

        const mapRef = ref(null);
        const activeID = ref(null);

        const infoWindow = computed(() => {
          return activeID.value ? true : false;
        })

        let markers = ref([]);

        const openedMarkerID = ref(null);

        const centerBounds = async () => {
          if (!markers.value.length) return;
          if (!window.google?.maps?.LatLngBounds) return;
          const bounds = new window.google.maps.LatLngBounds();

            markers.value.forEach((location) => {
              bounds.extend({ lat: Number(location.position.lat), lng: Number(location.position.lng) });
            })
            
            if (mapRef.value?.ready) {
              setTimeout(() => {
                mapRef.value.map.fitBounds(bounds);
              }, 1000)
            }

            console.log({markers: markers.value})
        }

        const center = ref({ lat: -35, lng: 150 });

        const handleMapClick = () => {
          markers.value.forEach((marker) => {
            marker.active = false;
          })
        }

        const handleInfoWindow = (m) => {
          // Set all to false
          // markers.value.forEach((marker) => {
          //   marker.active = false;
          // })
          m.active = !m.active
        }

        watch(results, debounce((res) => {
            markers.value = res.data.reduce((markers, cur) => {
                let marker = {};
                if (cur?.latitude && cur?.longitude) {

                  let type = "";

                  switch(cur?.template?.value) {
                    case "projects/gallery":
                      type = "gallery";
                      break;
                    case "projects/location":
                      type = "location";
                      break;
                    default:
                      type = "full";
                      break;
                  }

                    marker = reactive({
                        id: cur.id,
                        title: cur.title,
                        permalink: cur.permalink,
                        icon: `/assets/icons/map/marker-${type}.svg`,
                        thumbnail: cur.thumbnail?.url,
                        excerpt:
                            cur.excerpt ??
                            truncateWords(cur.content, 40, "..."),
                        position: {
                            lat: cur.latitude,
                            lng: cur.longitude,
                        },
                        address: cur.location,
                        active: ref(false),
                        type
                    });
                    markers.push(marker);
                }
                return markers;
            }, []);

            centerBounds();
        }, 500, false, false));

        return {
          activeID,
          mapRef,
            options,
            handleMapClick,
            filters,
            baseFilters,
            markers,
            openedMarkerID,
            handleInfoWindow,
            center,
            ClusterRenderer,
            responsiveControls,
        };
    },
};
</script>

<style lang="scss">
#app {
    .vue-map-container {
        display: flex;
        flex-direction: column;
        .vue-map {
            flex-grow: 1;
        }
    }
    .map-filter-controls {
        h4 {
            @apply pb-0 mb-0 border-0;
        }
    }
    .cluster > div {
        font-family: var(--font-headings);
        display: flex;
        justify-content: center;
        align-items: center;
        top: 0;
        bottom: 0;
    }
    .project-map {
      height: calc(100vh - var(--project-map-header-height));
      .btn {
        &:visited {
          color: var(--color-white);
        }
      }
    }
}
</style>
