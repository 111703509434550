<template>
    <div class="range-slider pt-12 px-3">
        <slot name="before-input" />
        <div
            class="checkbox-group__options rounded w-full flex flex-col font-bold"
            :class="[columns]"
        >
            <Slider :min="0" :max="options.max" :step="options.step" v-model="selected" :format="format" />
        </div>
        <slot name="after-input" />
    </div>
</template>

<script>
import { onMounted, computed, toRefs } from "vue";
import eventBus from "../../services/eventBus";

export default {
    props: {
        options: {
            type: [Object],
            required: false,
        },
        old: {
            type: [Array],
        },
        handle: {
            type: String,
            required: false,
            default: "",
        },
        collection: String,
        columns: {
            type: String,
            required: false,
            default: "",
        },
    },
    emits: ["input", "clearFilters", "update:modelValue"],
    setup(props, context) {
        const { handle, columns, collection } = props;
        const { options, old } = toRefs(props);

        const collectionTitle = computed(() => {
            return collection ? collection.replaceAll("_", " ") : null;
        });

        const selected = computed({
            get() {
              console.log(old.value)
                return old?.value ? [old.value?.min, old.value?.max] : [options?.value?.min, options?.value?.max]
            },
            set: (e) => {
              e = {
                min: e[0],
                max: e[1]
              }
              
              // context.emit("update:modelValue", [e]);
              if (e.min === options.value.min && e.max === options.value.max) {
                  e = {};
              }

              context.emit("input", e);
            }
        })

        const optionsLength = computed(() => {
            return Object.entries(options).length;
        });

        const isParent = (option) => {
            return option.includes("-parent");
        };

        const clearFilters = () => {
            selected.value = "";
            context.emit("clearFilters");
        };

        const format = (value) => {
        return `€${Math.round(value)}`
        }

        onMounted(() => {
          eventBus.$on('clearFilters', () => {
            alert('test');
            selected.value = [options.value.min, options.value.max];
          });
        });

        return {
            collectionTitle,
            clearFilters,
            selected,
            columns,
            handle,
            isParent,
            optionsLength,
        };
    },
};
</script>

<style>
:root {
  --slider-tooltip-bg: rgb(var(--color-primary));
  --slider-connect-bg: rgb(var(--color-primary));
}
</style>
