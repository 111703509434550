<template>
    <v-loading-spinner v-if="loading" />
    <div v-else-if="hasMore">
      <button v-is-intersecting="loadMore" @click="loadMore">Load More</button>
    </div>
</template>

<script setup>
import { ref, computed, toRefs } from "vue";

const props = defineProps({
    pagination: Object,
    loading: Boolean,
});

const emit = defineEmits(["page-changed"]);

const { pagination } = toRefs(props);

const perPage = computed(() => {
    return pagination.value?.per_page;
});

const currentPage = ref(pagination.value?.current_page ?? 1);

const loadMore = () => {
    const startIndex = (currentPage.value - 1) * perPage?.value;
    const endIndex = startIndex + perPage?.value;
    currentPage.value++;
    // url.page = currentPage.value;
    emit("loadmore", currentPage.value);
};

const totalResults = computed(() => {
    return Math.ceil(pagination.value?.total);
});

const hasMore = computed(() => {
    return perPage?.value ? currentPage.value * perPage?.value < totalResults?.value : false;
});
</script>
