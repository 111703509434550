<script setup>
import { onMounted, provide } from "vue";
import { useFilter } from "../../composables/useFilter";
import { contentAPI } from "../../services/contentAPI";
const {
    filters,
    collection,
    headerIcons,
    filterControlClass,
    baseFilters,
    inline,
    responsiveControls,
} = defineProps({
    filters: Object,
    inline: {
        type: Boolean,
        default: false,
    },
    collection: String,
    headerIcons: {
        type: Boolean,
        default: true,
    },
    baseFilters: {
        type: Object,
        default: () => ({}),
    },
    filterControlClass: {
        type: String,
        default: "",
    },
    responsiveControls: {
        type: Boolean,
        default: true,
    },
});

const { fetchResults, activeFilterCount, clearFilters } = useFilter(contentAPI, {
    collection,
    param: filters[0]?.filter_by?.value || "taxonomies",
    condition: false,
    baseFilters,
});

provide("responsiveControls", responsiveControls);
// provide("filterControlClass", filterControlClass);

onMounted(() => {
    // fetchResults();
    console.log("filters", activeFilterCount);
});
</script>

<template>
    <div class="flex-grow">
      <button class="text-sm" v-if="activeFilterCount" @click="clearFilters">Clear All Filters ({{ activeFilterCount }})</button>
        <v-filter-control
            :inline="inline"
            :filter="filter"
            :filter-control-class="filterControlClass"
            :collection="collection"
            :headerIcons="headerIcons"
            :baseFilters="baseFilters"
            v-for="(filter, index) in filters"
            :key="filter.id"
            :index="index"
        />
    </div>
</template>
