<template>
    <div>
        <h4
            class="accordion__header no-prefix border-b border-primary flex gap-3 items-center pb-3"
            :class="[!isCollapsed ? 'open' : '']"
            @click="isCollapsed = !isCollapsed"
        >
            <v-icon
                v-if="headerIcons"
                class="text-primary self-start text-2xl"
                :name="filter.icon"
            />
            <div class="d-flex flex-col">
              {{ filter.title }}
              <div class="text-sm font-body" v-if="filter.instructions">{{ filter.instructions }}</div>
            </div>
            <v-icon
                v-if="isMobile && responsiveControls"
                name="arrow-right-s-line"
                class="transition-transform ml-auto text-4xl"
                :class="[{ 'rotate-90': !isCollapsed }]"
            />
        </h4>
        <div
            class="p-0"
            :class="[{ hidden: isCollapsed && responsiveControls }]"
        >
            <component
                :is="`field-${filter.style.value}`"
                :old="old"
                :clear-all="clearAll"
                @clearFilters="clearFilters"
                @input="handleCheck"
                :collection="collection"
                :options="filter.filter_by.values"
                :handle="filter.filter_by.handle"
                v-if="filter.filter_by.values"
            />
        </div>
    </div>
</template>

<script>
import { onMounted, ref, toRefs, reactive, inject } from "vue";
import { isAbove } from "../../functions/breakpoints";

export default {
    props: {
      index: Number,
        filter: Object,
        headerIcons: Boolean,
        url: Object,
        expanded: {
            type: Boolean,
            default: false,
        },
        collection: String,
        clearAll: Boolean,
    },
    emits: ["filter-change", "clearFilters"],
    setup(props, context) {
        const { index, filter, expanded, collection, headerIcons, url } = props;
        const { clearAll } = toRefs(props);

        const old = ref(null);

        const taxonomy = ref("");
        const terms = reactive({});

        const isCollapsed = ref(!expanded);
        const isMobile = ref(false);
        const responsiveControls = inject("responsiveControls", true);

        const handleCheck = (val) => {
          context.emit("filter-change", val);
        };

        const handleGroupSelect = (value) => {
            if (clearAll.value) {
                return "";
            }
        };

        const updateOldValues = () => {
          // Pre-select values if they are in the URL

          let url_values = null;
          let selected = null;

          switch(filter.style.value) {
            case 'range_slider':
              url_values = String(url[`field[${[filter.id]}]`]);
              if (url_values !== '' && url_values !== 'undefined') {
                selected = url_values;
              }
              break;
            case 'range_slider_dual':
              url_values = [
                url[`field[${[filter.id]}][min]`], 
                url[`field[${[filter.id]}][max]`]
              ];

              if (!url_values.some(item => item !== '')) {
                return;
              }

              selected = {
                min: url_values[0] ? url_values[0] : filter.filter_by.values.min,
                max: url_values[1] ? url_values[1] : filter.filter_by.values.max,
              }

              break;
            default:
              url_values = String(url[`field[${[filter.id]}]`]).split("|");
              selected = url_values.filter(value => filter_terms.includes(value)).map(value => `${filter.id}::${value}`);
          }

          if (selected) {
            handleCheck(selected);
          }

          return selected;
        }

        const clearFilters = () => context.emit("clearFilters");

        onMounted(async () => {
          console.log({filter})
          
            // Set the title of taxonomy
            taxonomy.value = filter.title;

            const OnMobileState = (bool) => {
                if (!bool) {
                    isMobile.value = true;
                    isCollapsed.value = true;
                } else {
                    isMobile.value = false;
                    if (index >= 1) {
                        isCollapsed.value = true;
                    }
                }
            };

            // Check if the screen is mobile on load
            OnMobileState(isAbove.md.matches);

            // Check if the screen is mobile on resize
            isAbove.md.addListener((e) => OnMobileState(e.matches));

            // Pre-select values if they are in the URL
            const old_values = updateOldValues();
            if (old_values) {
              old.value = old_values;
            }

            terms.options = filter.terms;
        });

        return {
            taxonomy,
            clearAll,
            collection,
            clearFilters,
            old,
            headerIcons,
            terms,
            handleCheck,
            expanded,
            isCollapsed,
            isMobile,
            responsiveControls,
        };
    },
};
</script>

