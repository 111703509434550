<template>
    <Suspense>
        <template #default>
            <ProductZoomer
                :base-images="images"
                :base-zoomer-options="zoomerOptions"
                :navigation="navigation"
                :arrows="arrows"
                v-bind="$attrs"
            />
        </template>
        <template #fallback>
            <div class="flex animate-pulse flex-col gap-6" v-bind="$attrs">
                <div class="preview-img bg-gray-300 h-160"></div>
                <ul v-if="navigation" class="m-0 grid gap-3 grid-cols-4">
                    <li class="bg-gray-300 h-32"></li>
                    <li class="bg-gray-300 h-32"></li>
                    <li class="bg-gray-300 h-32"></li>
                    <li class="bg-gray-300 h-32"></li>
                </ul>
            </div>
        </template>
    </Suspense>
</template>

<script setup>
import { useAttrs } from 'vue'

const attrs = useAttrs()


const { images } = defineProps({
    images: {
        type: Array,
        required: true,
        default: [],
    },
});

const navigation = false;
const arrows = false;

const zoomerOptions = {
    zoomFactor: 3, // scale for zoomer
    pane: "pane", // three type of pane ['pane', 'container-round', 'container']
    hoverDelay: 300, // how long after the zoomer take effect
    namespace: "zoomer", // add a namespace for zoomer component, useful when on page have mutiple zoomer
    move_by_click: true, // move image by click thumb image or by mouseover
    scroll_items: 4, // thumbs for scroll
    choosed_thumb_border_color: "#bbdefb", // choosed thumb border color
    scroller_button_style: "line",
    scroller_position: "bottom",
    zoomer_pane_position: "right",
};
</script>

<style></style>
