<template>
    <div v-if="colour.icon || colour.image_url" class="flex flex-grow gap-2 items-center bg-lightgray border-2 rounded-full" 
    :class="[isActive ? 'border-secondary' : 'border-white']">
      <div class="border-4 border-white rounded-full">
        <img
          @click="debug(colour)"
          class="object-contain cursor-pointer mix-blend-multiply w-10 h-10 rounded-full"
          :src="colour?.icon?.url || colour?.image_url"
          :title="colour.title"
        />
      </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { useProductColour } from "../../composables/useProductColour";


const { colour } = defineProps({
    colour: Object,
});

const { getColour, setColour } = useProductColour();
const isActive = computed(() => getColour?.value.slug === colour.slug);


const debug = (colour) => {
  console.time('colorSwap'); 
  setColour(colour);
}
</script>

<style></style>
