<script setup>
import { useCart } from "../../composables/useCart";
import { onMounted, ref } from "vue";
import { contentAPI } from "../../services/contentAPI";
import { getStorageWithExpiry } from "../../functions/localStorage";
import axios from "axios";

const { getCart, emtpyCart } = useCart();

const formEL = ref(null);
const productsField = ref(null);
const success_dialog = ref(null);

const createErrorHtml = (field, message) => {
    field.setAttribute("aria-invalid", "true");
    field.setAttribute("aria-describedby", `error-${field.name}`);
    const error = document.createElement("div");
    error.classList.add("text-red-600", "field-validation-error");
    error.innerHTML = message;
    field.parentNode.append(error);
};

onMounted(() => {
    formEL.value = document.querySelector(".form-request_a_quote") || null;
    formEL.value &&
        (productsField.value = formEL.value.querySelector("[name='products']"));

    if (productsField.value) {
        productsField.value.closest(".input-group").classList.add("hidden");
    }
    // Very basic validation error handling
    formEL.value.addEventListener("input", (e) => {
        let input_group = e.target.closest(".input-group");
        if (input_group) {
            const hasError = input_group.querySelector(
                ".field-validation-error"
            );
            if (!hasError) return;
            if (e.target.validity.valid) {
                hasError.classList.add("hidden");
            } else {
                hasError.classList.remove("hidden");
            }
        }
    });
});

const handleSubmit = async () => {
    if (!formEL.value) return;

    const productsField = formEL.value.querySelector("[name='products']");

    productsField.value = JSON.stringify(getCart.value);

    // Basic check that required fields are filled out
    if (!formEL.value.reportValidity()) return;

    // get the formData
    const formData = new FormData(formEL.value);
    formData.append("product_data", JSON.stringify(getCart.value));

    // loop through formData and log key / values (debug)
    // for (const [key, value] of formData.entries()) {
    //     console.log(key, value);
    // }

    // const res = await contentAPI.post("/!/forms/request-quote", formData);
    axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

    axios.post(formEL.value.action, formData).then((response) => {
        if (response.data.success) {
            emtpyCart();
            formEL.value.reset();
            if (success_dialog.value) {
                success_dialog.value.showModal();
            }
        } else {
            // Loop through form fields and add an error matching the name
            for (const field of formEL.value.elements) {
                if (field.name in response.data.submission) {
                    createErrorHtml(
                        field,
                        response.data.submission[field.name]
                    );
                }
            }
        }
    });
};
</script>

<template>
    <div class="relative">
        <h5 class="bg-lightgray p-4">
            <!-- <v-icon
                name="cart"
                class="relative flex flex-shrink-0 cursor-pointeritems-center"
                :style="'gap: 0.6em'">
            </v-icon> -->
            Products
        </h5>
        <div v-if="getCart.length" class="flex flex-col w-full p-4">
            <ul class="m-0 p-0 divide-y">
                <cart-item
                    class="py-3"
                    v-for="item in getCart"
                    :key="item"
                    :item="item"
                ></cart-item>
            </ul>
        </div>
        <em class="py-4 block" v-else
            >You need to add products to request a quote.</em
        >
        <a
            @click="handleSubmit"
            v-if="getCart.length"
            class="btn btn--primary w-full text-center"
            href="#"
            >Request Quote</a
        >
        <dialog ref="success_dialog" class="relative p-0">
            <i
                @click="success_dialog.close()"
                class="fas cursor-pointer fa-times-circle absolute top-0 right-0 text-black text-xl shadow flex items-center justify-center w-8 h-8 bg-white"
            ></i>
            <h3 class="bg-green-600 font-normal text-white p-4 w-full">
                Quote sent successfully
            </h3>
            <div class="modal__inner p-6">
                <p>
                    Your quote has been sent and a member of our team will be in
                    touch as soon as we can.
                </p>
            </div>
        </dialog>
    </div>
</template>

<style></style>
