<script setup>
import { useCart } from "../../composables/useCart";
import { ref } from "vue";

const { inCart, getCart, addToCart, validateCart, removeFromCart } = useCart();

const isHovering = ref(false);

const showCart = () => {
    isHovering.value = true;
};
const hideCart = () => {
    validateCart();
    isHovering.value = false;
};
</script>

<template>
    <div class="relative" v-click-outside="hideCart" @click="showCart">
        <v-icon
            name="cart"
            class="relative flex-shrink-0 cursor-pointer text-secondary lg:text-primary items-center"
        >
            <span class="text-white lg:text-primary">Cart</span>
            <span
                v-if="getCart.length"
                class="absolute bg-tertiary cart-count text-white rounded-full w-4 h-4 text-xs flex items-center justify-center bottom-0 right-0 translate-x-2 translate-y-2"
                >{{ getCart.length }}</span
            >
        </v-icon>
        <div
            v-if="getCart.length"
            class="absolute shadow-lg flex flex-col w-[240px] bg-white p-6 top-full z-[2000]"
            v-show="isHovering"
        >
            <ul class="m-0 p-0 divide-y">
                <cart-item
                    class="py-3 text-gray-500"
                    v-for="item in getCart"
                    :key="item"
                    :item="item"
                ></cart-item>
            </ul>
            <a
                class="btn btn--primary text-sm mt-5 whitespace-nowrap self-center"
                href="/request-a-quote"
                >Request Quote</a
            >
        </div>
    </div>
</template>

<style></style>
