<template>
    <div
        class="card card__case-study relative overflow-hidden flex flex-col flex-1"
    >
        <div v-if="image?.url" class="card-image mb-4">
            <img
                loading="lazy"
                class="w-full object-cover"
                :alt="image.alt"
                :src="image.url"
            />
        </div>
        <div class="card-body flex-grow mb-4">
            <h4 class="text-xl text-primary">{{ title }}</h4>
            <span v-html="content" />
        </div>
        <a class="card-link no-underline" :href="permalink">
            <span class="card-link__text font-bold text-primary"
                >Learn More</span
            >
        </a>
    </div>
</template>

<script setup>
import { cardProps, useCard } from "../../composables/useCard";
const { entry, thumbnail } = defineProps({
    ...cardProps,
});

const { title, content, image, permalink } = useCard(entry, thumbnail, {
    image_params: "w=1152&h=700&fit=crop",
});
</script>
