<template>
    <swiper v-bind="sliderProps">
        <swiper-slide
            v-for="(slide, index) in normaliseSlides"
            :key="`vue-default-slot-${index}`"
        >
            <div class="buildamic-row items-center">
                <div class="col col-4">
                    <img
                        :src="`/img/${slide?.image_id}?w=800&h=640`"
                        class="rounded-tr-[6rem]"
                        :alt="slide.title"
                    />
                </div>
                <div class="buildamic-column col col-8 flex-1 flex flex-col gap-4">
                    <i class="fas fa-quote-left text-3xl text-primary mb-3" />
                    <h3 class="uppercase font-semibold m-0">{{ slide.title }}</h3>
                    <div
                        class="mb-2"
                        v-html="truncateWords(slide.content, 60, '...')"
                    />
                    <div
                        class="swiper-pagination swiper-pagination-bullets"
                    ></div>
                </div>
            </div>
        </swiper-slide>
        <div v-if="sliderProps.navigation">
            <div
                class="swiper-button-prev absolute top-1/2 -translate-y-1/2 left-0 fas fa-chevron-left"
                :class="`swiper-button-prev-${name}`"
            ></div>
            <div
                class="swiper-button-next absolute top-1/2 -translate-y-1/2 right-0 fas fa-chevron-right"
                :class="`swiper-button-next-${name}`"
            ></div>
        </div>
    </swiper>
</template>
<script>
// Import Swiper Vue.js components
import { computed } from "vue";
import SwiperCore, { Pagination, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import { truncateWords } from "../../functions/helpers";

SwiperCore.use([Pagination, Navigation]);

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default {
    components: {
        Swiper,
        SwiperSlide,
    },
    props: {
        name: {
            type: String,
            default: "",
        },
        slides: {
            type: Array,
            default: () => [],
        },
    },
    setup({ slides, name, ...rest }, { slots }) {
        const sliderProps = {
            spaceBetween: 30,
            pagination: {
                el: ".swiper-pagination",
                clickable: true,
                renderBullet: function (index, className) {
                    return `<span class="dot swiper-pagination-bullet"></span>`;
                },
            },
            navigation: {
                nextEl: `.swiper-button-next-${name}`,
                prevEl: `.swiper-button-prev-${name}`,
            },
            autoplay: {
                delay: 3000,
            },
            loop: true,
            ...rest,
        };

        console.log({slides})

         const normaliseSlides = computed(() => {
            return slides.map((slide) => ({
                    title: slide?.title ?? slide?.slide_title ?? null,
                    image_id: slide?.thumbnail?.id ?? slide?.slide_image?.id ?? null,
                    content: slide?.content ?? slide?.slide_content ?? null,
                }));
        });

        console.log({normaliseSlides})


        return {
            normaliseSlides,
            truncateWords,
            name,
            sliderProps,
        };
    },
};
</script>

<style lang="scss">
.swiper {
    @apply flex flex-col;
}

.swiper-button-next,
.swiper-button-prev {
    position: static;
    width: auto;
    height: auto;
    margin: 0;
}

.swiper-button-next.swiper-button-disabled,
.swiper-button-prev.swiper-button-disabled {
    opacity: 0.35;
    cursor: auto;
    pointer-events: none;
}

.swiper-button-next:after,
.swiper-button-prev:after {
    display: none;
}

.swiper-button-next,
.swiper-button-prev {
    &:before {
        @apply drop-shadow text-white text-3xl p-4;
    }

    &::after,
    &::after {
        @apply hidden;
    }
}

.swiper-button-next,
.swiper-button-prev {
    &:hover {
        cursor: pointer;
    }
    &:not(.swiper-button-disabled) {
        color: var(--color-primary);
    }
}

.swiper-button-prev,
.swiper-container-rtl .swiper-button-next {
    left: unset;
    right: unset;
}

.swiper-button-prev:after,
.swiper-container-rtl .swiper-button-next:after {
    content: "prev";
}

.swiper-button-next,
.swiper-container-rtl .swiper-button-prev {
    right: unset;
    left: unset;
}

.swiper-button-next:after,
.swiper-container-rtl .swiper-button-prev:after {
    content: "next";
}

.swiper-button-next.swiper-button-white,
.swiper-button-prev.swiper-button-white {
    --swiper-navigation-color: #ffffff;
}

.swiper-button-next.swiper-button-black,
.swiper-button-prev.swiper-button-black {
    --swiper-navigation-color: #000000;
}

.swiper-button-lock {
    display: none;
}

.swiper-horizontal > .swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal,
.swiper-pagination-custom,
.swiper-pagination-fraction {
    @apply order-2 static mt-auto w-fit;
    .swiper-pagination-bullet {
        @apply rounded-none bg-transparent border border-primary opacity-100;
        &-active {
            @apply bg-primary;
        }
    }
}

.swiper-wrapper {
    order: 1;
}
</style>

<style lang="scss" scoped>
.swiper-slide {
    @apply flex flex-grow h-auto;
}
</style>
