<template>
    <div>
        <h4
            class="accordion__header no-prefix border-b border-primary flex gap-3 items-center pb-3"
            :class="[!isCollapsed ? 'open' : '']"
            @click="isCollapsed = !isCollapsed"
        >
            <v-icon
                v-if="headerIcons"
                class="text-primary self-start text-2xl"
                :name="filter.icon"
            />
            <div class="d-flex flex-col">
                <span>{{ filter.title }}</span>
                <div class="text-sm font-body" v-if="filter.instructions">
                    {{ filter.instructions }}
                </div>
            </div>
            <v-icon
                v-if="isMobile && responsiveControls"
                name="arrow-right-s-line"
                class="transition-transform ml-auto text-4xl"
                :class="[{ 'rotate-90': !isCollapsed }]"
            />
        </h4>
        <div
            class="p-0"
            :class="[{ hidden: isCollapsed && responsiveControls }]"
        >
            <component
                :is="`taxonomies-${filter.style.value}`"
                :old="selected"
                :clear-all="clearAll"
                @clearFilters="clearFilters"
                @input="handleCheck"
                :collection="collection"
                :options="terms.options"
                v-if="terms.options"
            />
        </div>
    </div>
</template>

<script>
import { onMounted, ref, toRefs, reactive, inject, watch, computed } from "vue";
import { isAbove } from "../../functions/breakpoints";

export default {
    props: {
        index: Number,
        filter: Object,
        headerIcons: Boolean,
        url: Object,
        expanded: {
            type: Boolean,
            default: false,
        },
        collection: String,
        clearAll: Boolean,
    },
    emits: ["filter-change", "clearFilters"],
    setup(props, context) {
        const { index, filter, expanded, collection, headerIcons, url } = props;
        const { clearAll } = toRefs(props);

        const old = ref([]);
        const selected = computed(() => {
            return old.value;
        });

        const taxonomy = ref("");
        const terms = reactive({});

        const isCollapsed = ref(!expanded);
        const isMobile = ref(false);
        const responsiveControls = inject("responsiveControls", true);

        const handleCheck = (items) => {
            context.emit("filter-change", items);
        };

        const handleGroupSelect = (value) => {
            if (clearAll.value) {
                return "";
            }
        };

        const updateOldValues = () => {
          // Pre-select values if they are in the URL
          const url_values = String(url[`taxonomies[${[filter.id]}]`]).split("|");
          console.log({ url_values })
          const filter_terms = Object.values(filter.terms).map(term => term.slug)

          // Array intersection
          const selected = url_values.filter(value => filter_terms.includes(value)).map(value => `${filter.id}::${value}`);

          if (selected.length) {
            handleCheck(selected);
          }

          return selected;
        }


        const clearFilters = () => context.emit("clearFilters");

        onMounted(async () => {

            // Set the title of taxonomy
            taxonomy.value = filter.title;

            const OnMobileState = (bool) => {
                if (!bool) {
                    isMobile.value = true;
                    isCollapsed.value = true;
                } else {
                    isMobile.value = false;
                    if (index >= 1) {
                        isCollapsed.value = true;
                    }
                }
            };

            // Check if the screen is mobile on load
            OnMobileState(isAbove.md.matches);

            // Check if the screen is mobile on resize
            isAbove.md.addListener((e) => OnMobileState(e.matches));

            old.value = updateOldValues();

            terms.options = filter.terms;
            
        });

        return {
            taxonomy,
            clearAll,
            collection,
            clearFilters,
            old,
            selected,
            headerIcons,
            terms,
            handleCheck,
            expanded,
            isCollapsed,
            isMobile,
            responsiveControls,
        };
    },
};
</script>

<style lang="scss">
.accordion__header {
    padding-left: 0;
    padding-right: 0;
    span {
      display: flex;
      gap: 1rem;
      align-items: center;
      &::after {
        // add font awesome chevron right
        content: "\f0da";
        color: rgba(var(--color-secondary));
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        transition: all 0.2s ease-in-out;
    }
    }
    &.open span::after {
        transform: rotate(90deg);
    }
}

.accordion__content {
    padding-left: 0;
    padding-right: 0;
}
</style>
