let observer

export default {
  beforeMount(el, binding, vnode) {
    // Check if the el is currently intersection, and if so, call binding()

    // Create an intersection observer
    observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          binding.value()
        }
      })
    })

    // Start observing the el
    observer.observe(el)
  }
}

