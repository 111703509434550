<template>
    <div ref="masonryContainer" class="grid lg:grid-cols-3 gap-6">
        <div
            v-for="(item, index) in items"
            :key="item?.props?.src"
            :style="[
                `gridRow: auto / span ${randomList.includes(index) ? 2 : 1}`,
                `gridColumn: auto / span ${randomList.includes(index) ? 2 : 1}`,
            ]"
        >
            <vnode class="h-full" :vnode="item" />
        </div>
    </div>
</template>
<script setup>
import { useSlots, computed, ref } from "vue";

const slots = useSlots();
const items = computed(() => slots && slots.default && slots.default());

const FrequencyMin = 1;
const FrequencyMax = 2;
const randomList = [];

for (let i = 0; i < items.value.length; i++) {
    i = i + Math.floor(Math.random() * FrequencyMax + FrequencyMin) * 2;
    randomList.push(i);
}

</script>
<style lang="scss"></style>
