const handleClick = () => {

}

export default {
    beforeMount(el, binding) {
        let target = binding.value || 'body';
        let block = 'start';

        if (typeof target === 'object') {
            target = binding.value?.target || target;
            block = binding.value?.block || block
        }

        target = document.querySelector(target);
        if (!target) return

    el.scrollToEvent = function () {
            target.scrollIntoView({
                block,
                behavior: 'smooth'
            })
        };


    },
    mounted(el) {
        el.addEventListener('click', el.scrollToEvent, true);
    },
    unMounted() {
        el.removeEventListener('click', el.scrollToEvent, true);
    }
}

