<template>
    <div>
        <h4
            class="accordion__header no-prefix border-b border-primary flex gap-3 items-center pb-3"
            :class="[!isCollapsed ? 'open' : '']"
            @click="isCollapsed = !isCollapsed"
        >
            <v-icon
                v-if="headerIcons"
                class="text-primary self-start text-2xl"
                :name="filter.icon"
            />
            <div class="d-flex flex-col">
              {{ filter.title }}
              <div class="text-sm font-body" v-if="filter.instructions">{{ filter.instructions }}</div>
            </div>
        </h4>
        <div
            class="p-0"
        >
            <search-input @input="handleCheck" />
        </div>
    </div>
</template>

<script>
import { onMounted, ref, toRefs, reactive, inject } from "vue";

export default {
    props: {
      index: Number,
        filter: Object,
        headerIcons: Boolean,
        url: Object,
        clearAll: Boolean,
    },
    emits: ["filter-change", "clearFilters"],
    setup(props, context) {
        const { filter, collection, headerIcons, url } = props;
        const { clearAll } = toRefs(props);

        const handleCheck = (search) => {
          console.log({search})
            context.emit("filter-change", search);
        };

        return {
          handleCheck
        }
    },
};
</script>

