export class ClusterRenderer {
  render({ count, position }, stats, map) {
    const color =
      count > Math.max(4, stats.clusters.markers.mean)
        ? "#D3232A"
        : "#0000ff";

    // create svg url with fill color
    const svg = window.btoa(`
        <svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240">
            <circle cx="120" cy="120" opacity="1" r="94" fill="white" />
            <circle cx="120" cy="120" opacity="1" r="90" />
            <circle cx="120" cy="120" opacity=".2" r="120" />
        </svg>`);


    // create marker using svg icon
    return new google.maps.Marker({
      position,
      icon: {
        url: `data:image/svg+xml;base64,${svg}`,
        scaledSize: new google.maps.Size(45, 45),
      },
      label: {
        text: String(count),
        color: "rgba(255,255,255,0.9)",
        fontSize: "12px",
      },
      // adjust zIndex to be above other markers
      zIndex: 1000 + count,
    });
  }
}