import { ref, computed } from 'vue';
import { contentAPI } from "../services/contentAPI";

const useEntries = (collection) => {

  const fetch = async (params = "") => {
    return await contentAPI.get(
      `/collections/${collection}/entries/?${decodeURIComponent(
        params
      )}`
    );
  }

  return {
    fetch
  };
}

export { useEntries }
