import { ref, computed, watch } from 'vue';

const cartState = ref(JSON.parse(localStorage.getItem("cart") || "[]"));

const useCart = () => {

    const inCart = (entryId) => computed(() =>
        cartState.value.find((item) => item.entryId === entryId)
    );

    const getCart = computed(() => {
        return cartState.value;
    })

    const addToCart = (entryId) => {
        if (inCart(entryId).value) {
            increaseCartQty(entryId);
        }
        cartState.value.push({
            entryId,
            quantity: 1,
        })

        updateStorage()
    };

    const emtpyCart = () => {
        cartState.value = [];
        updateStorage()
    }

    const removeFromCart = (entryId) => {
        cartState.value = cartState.value.filter((item) => item.entryId !== entryId);
        updateStorage()
    }

    const validateCart = () => {
        cartState.value = cartState.value.filter(el => el.quantity > 0)
        updateStorage();
    }

    const updateStorage = () => {
        localStorage.setItem("cart", JSON.stringify(cartState.value));
    }

    return {
        inCart,
        getCart,
        addToCart,
        validateCart,
        removeFromCart,
        emtpyCart
    };
}

export { useCart }
