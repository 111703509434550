<template>
  <!-- A standard text input for search with a magnifying icon that acts as the button -->
  <div class="relative">
    <input
      type="text"
      class="w-full border border-primary rounded-lg py-2 px-3 pr-10 text-sm font-body"
      placeholder="Search"
      v-model="search"
      @keypress.enter="handleSearch"
    />
    <button
      class="absolute top-0 border-0 hover:bg-transparent right-0 h-full w-10 text-primary flex items-center justify-center"
      @click="handleSearch"
    >
      <i class="fas fa-search"></i>
    </button>
  </div>
</template>

<script>
import { onMounted, toRefs, ref } from "vue";
import eventBus from "../../services/eventBus";

export default {
    props: {
        options: {
            type: [Object],
            required: false,
        },
        old: {
            type: Number,
        },
        handle: {
            type: String,
            required: false,
            default: "",
        }
    },
    emits: ["input", "clearFilters", "update:modelValue"],
    setup(props, context) {
        const { handle } = props;
        const { options, old } = toRefs(props);

        const search = ref('');

        const handleSearch = () => {
          context.emit("input", search.value);
        };

        const clearFilters = () => {
            search.value = '';
            context.emit("clearFilters");
        };

        onMounted(() => {
          eventBus.$on('clearFilters', () => {
            search.value = '';
          });
        });

        return {
          search,
          handleSearch,
            clearFilters,
            handle,
        };
    },
};
</script>

<style>
:root {
  --slider-tooltip-bg: rgb(var(--color-primary));
  --slider-connect-bg: rgb(var(--color-primary));
}
</style>
