<template>
  <a @click="handleClick" class="flex items-center gap-2 text-primary text-opacity-100" href="#">
      <span class="flex rounded-full p-2 bg-primary bg-opacity-100">
          <v-icon class="text-white" color="white" :name="icon"></v-icon>
      </span>
      View All {{ parentTitle }}
  </a>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { url, useFilter } from "../../composables/useFilter";
import { contentAPI } from "../../services/contentAPI";
import { set } from '@vueuse/core';

const { fetchResults } = useFilter(contentAPI, {
  collection: "products",
})

const props = defineProps({
  parentCat: {
    type: String,
    default: null
  },
  parentTitle: {
    type: String,
    default: null
  },
  icon: {
    type: Object,
    default: null
  }
})

const handleClick = () => {
  url["taxonomies[product_category]"] = props.parentCat
  setTimeout(() => {
    fetchResults()
  }, 1000)
}

</script>
<style lang="">
  
</style>