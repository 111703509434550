import { ref } from "vue"
const mobileNavOpened = ref(false)

export const useHamburger = () => {
    const toggleMobileMenu = () => {
        if (!document.body.classList.contains("--mobile-menu-open")) {
            document.body.classList.add("--mobile-menu-open");
            listeners(document.body, "click", "add", checkOutsideClick);
            mobileNavOpened.value = true;
        } else {
            document.body.classList.remove("--mobile-menu-open");
            listeners(document.body, "click", "remove", checkOutsideClick);
            mobileNavOpened.value = false;
        }
    }

    const listeners = (el, handler, action, cb) => {
        el[`${action}EventListener`](handler, cb);
    };

    const checkOutsideClick = (e) => {
        if (!e.target.closest("#mobile-nav")) {
            toggleMobileMenu();
        }
    };
    return {
        mobileNavOpened,
        toggleMobileMenu
    }
}
