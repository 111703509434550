<template>
    <div class="flex flex-col relative">
        <button
            @click="handleClick"
            class="btn px-4 w-fit btn--primary flex items-center justify-center text-center gap-2"
        >
            {{ buttonText }}
            <v-icon name="cart" class="flex-shrink-0 cursor-pointer" />
        </button>
        <!-- <transition name="fade">
            <a
                class="flex py-2 md:absolute items-center gap-2 -bottom-10"
                v-show="isInCart"
                href="/request-a-quote"
            >
                Continue to quote <i class="fas fa-chevron-right"></i>
            </a>
        </transition> -->
    </div>
</template>

<script setup>
import { useCart } from "../../composables/useCart";
import { computed, ref } from "vue";

const props = defineProps({
    entryId: {
        type: String,
        required: true,
    },
});

const entryId = ref(props.entryId);

const { inCart, addToCart, removeFromCart } = useCart();

const isInCart = computed(() => inCart(entryId.value).value);

const buttonText = computed(() => (isInCart.value ? "Remove" : "Add to quote"));

const handleClick = () => {
    if (inCart(entryId.value).value) {
        return removeFromCart(entryId.value);
    }

    addToCart(entryId.value);
};
</script>

<style>
.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s ease;
}

/* .fade-enter-active {
    max-height: 0px;
    position: absolute;
    top: 0;
} */

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}
</style>
