<template>
    <div class="range-slider pt-4 px-3">
        <slot name="before-input" />
        <div
            class="checkbox-group__options rounded w-full flex flex-col font-bold"
            :class="[columns]"
        > 
        <div class="flex justify-between mb-3 text-primary">
          <span>{{ options.min }}</span>
          <span>{{ options.max }}+</span>
        </div>
            <Slider :max="options.max" :step="options.step" v-model="selected" @change="handleChange" />
        </div>
        <slot name="after-input" />
    </div>
</template>

<script>
import { onMounted, computed, ref, toRefs } from "vue";
import eventBus from "../../services/eventBus";

export default {
    props: {
        options: {
            type: [Object],
            required: false,
        },
        old: {
            type: [String, Number],
        },
        handle: {
            type: String,
            required: false,
            default: "",
        },
        collection: String,
        columns: {
            type: String,
            required: false,
            default: "",
        },
    },
    emits: ["input", "clearFilters", "update:modelValue"],
    setup(props, context) {
        const { handle, columns, collection } = props;
        const { options, old } = toRefs(props);

        const collectionTitle = computed(() => {
            return collection ? collection.replaceAll("_", " ") : null;
        });

        const selected = ref(old?.value ? parseInt(old.value) : options.value.min)

        const optionsLength = computed(() => {
            return Object.entries(options).length;
        });

        const isParent = (option) => {
            return option.includes("-parent");
        };

        const handleChange = (val) => {
          context.emit("input", val);
        }

        const clearFilters = () => {
            selected.value = 1;
            context.emit("clearFilters");
        };

        onMounted(() => {
          eventBus.$on('clearFilters', clearFilters);
        });

        return {
            collectionTitle,
            clearFilters,
            handleChange,
            selected,
            columns,
            handle,
            isParent,
            optionsLength,
        };
    },
};
</script>

<style>
:root {
  --slider-tooltip-bg: rgb(var(--color-primary));
  --slider-connect-bg: rgb(var(--color-primary));
}
</style>
