<template>
  <div v-if="images.length">
    <generic-slider>
      <img class="w-full object-contain max-h-96" v-for="image in images" :key="image.id" :src="image.url" />
    </generic-slider>
  </div>
</template>

<script setup>
import { ref } from 'vue';
import { baseAPI } from "../../services/baseAPI";

const images = ref([]);

const props = defineProps({
  m: {
    type: Object,
    required: true,
  }
})

const fetchGallery = async() => {
  const endpoint = `collections/projects/entries?filter[id:is]=${props.m?.id}`;
  
  let {data} = await baseAPI.get(endpoint);

  // simulate long loading time
  // await new Promise(resolve => setTimeout(resolve, 5000));

  if (data.length && data[0]?.project_gallery?.length) {
    images.value = [...data[0].project_gallery]
  }
}

await fetchGallery();
</script>