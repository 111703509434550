<template>
    <div class="flex gap-6 p-2">
        <div v-if="m.thumbnail" class="preview-thumbnail">
            <img loading="lazy" class="max-h-32 aspect-square" :src="m.thumbnail" />
        </div>
        <div class="flex flex-col">
            <h3 class="m-0">
                <span v-if="m.type !== 'full'">{{ m.title }}</span>
                <a v-else :href="m.permalink">{{ m.title }}</a>
            </h3>
            <directions-link v-if="m.address" :address="m.address" :icon="m.icon" />
            <div class="mt-2 flex-grow text-gray-300" v-html="m.excerpt" />
        </div>
    </div>
</template>
<script setup>
 const props = defineProps({
    m: {
        type: Object,
        required: true,
    }
 })
</script>
<style lang=""></style>
