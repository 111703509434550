<template>
    <!-- <toggle-simple v-model="value">
        <v-icon
            name="heart"
            class="cursor-pointer"
            :class="[
                value
                    ? 'text-primary stroke-primary'
                    : 'stroke-primary text-white',
            ]"
        />
    </toggle-simple> -->
</template>

<script>
import { useFavourites } from "../../composables/useFavourites";
export default {
    name: "Fav Heart",
    props: {
        userId: {
            type: String,
            required: true,
        },
        entryId: {
            type: String,
            required: true,
        },
    },
    setup({ userId, entryId }) {
        const { value } = useFavourites(userId, entryId);

        return {
            value,
        };
    },
};
</script>

<style></style>
