<template>
    <div class="card bg-lightgray card__reviews rounded relative overflow-hidden flex-1">
        <div class="card-body p-6 lg:p-8">
          <div class="flex flex-col md:flex-row gap-3 items-center">
            <template v-if="image?.url">
                <div class="card-image rounded">
                    <a :href="permalink">
                        <img
                            loading="lazy"
                            class="w-full aspect-square rounded-full object-cover"
                            :alt="title"
                            :src="image.url"
                        />
                    </a>
                </div>
            </template>
            <!-- <div class="star-rating mb-6">
                <v-rating
                    v-if="star_rating"
                    :max="5"
                    :current="star_rating"
                ></v-rating>
            </div> -->
            <h4 class="text-lg font-bold mb-4">{{ main_quote }}</h4>
            <div class="text-sm">
                <em v-html="excerpt" />
                <span class="name block mt-4">&mdash; {{ title }}</span>
            </div>
          </div>
        </div>
    </div>
</template>

<script>
import { useTruncate } from "../../composables/useTruncate";
import { useCard } from "../../composables/useCard.js";
export default {
    props: {
        entry: Object,
        classes: {
            type: String,
            default: "bg-white",
        },
        actions: Array,
        titleIcon: {
            type: String,
            default: "chevron-right",
        },
    },
    setup({ entry }) {
        const ID = entry?.id || "";
        const star_rating = entry?.star_rating || "";
        const main_quote = entry?.main_quote || "";
        const content = entry?.content;
        const excerpt = useTruncate(entry?.content || "");
        const thumbnail = entry?.thumbnial || "";

        const { title, image, permalink } = useCard(entry, thumbnail, {
            image_params: "w=680&h=500&fit=crop",
        });

        console.log({image})

        return {
            title,
            star_rating,
            main_quote,
            ID,
            content,
            excerpt,
            permalink,
            image
        };
    },
};
</script>

<style></style>
