<template>
    <div class="card card__case-study relative overflow-hidden">
        <div
            class="card-inner border bg-lightgray overflow-hidden group relative mb-2"
        >
            <a :href="permalink" class="overflow-hidden">
                <img
                    loading="lazy"
                    v-if="imageURL"
                    class="w-full mix-blend-multiply card-thumb object-cover transition-transform duration-500 hover:scale-110"
                    :alt="title"
                    :src="imageURL"
                />
            </a>
            <div
                v-if="content && hasHover"
                class="card-body leading-relaxed absolute bottom-0 w-full text-white bg-primary bg-opacity-70 group-hover:translate-y-0 transition-transform transform translate-y-full p-2"
            >
                <span v-html="content" />
            </div>
        </div>
        <a class="card-link text-center" :href="permalink">
            <h4 class="card-link__text font-bold mb-0" :style="{ color }">
                {{ title }}
            </h4>
        </a>
    </div>
</template>

<script>
import { computed } from "vue";
import { truncateWords } from "../../functions/helpers";
export default {
    props: {
        entry: Object,
        color: String,
        hasHover: false,
        thumbnail: {
            type: String,
            default: "",
        },
        classes: {
            type: String,
            default: "bg-white",
        },
    },
    setup({ entry, classes, color, thumbnail }) {
        const title = entry?.title ? truncateWords(entry.title, 16, "...") : "";
        const content = entry?.content
            ? truncateWords(entry.content, 10, "...")
            : "";
        const ID = entry?.id || "";
        const permalink = entry?.permalink || "";
        const imageURL = computed(() => {
            return (
                thumbnail ||
                (entry?.thumbnail &&
                    `/img/${entry?.thumbnail?.id}?w=1400&h=1400&fit=crop`)
            );
        });

        return {
            title,
            content,
            imageURL,
            color,
            ID,
            classes,
            permalink,
        };
    },
};
</script>
