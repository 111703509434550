import { reactive, onMounted } from 'vue'
import { baseAPI } from "../services/baseAPI";
import { getStorageWithExpiry, setStorageWithExpiry } from '../functions/localStorage';

const productCache = getStorageWithExpiry("product_cache") || [];

export const useProduct = (productId) => {

    const product = reactive({})

    const checkForProductInCache = () => {
        return productCache.find(el => el.id === productId)
    }

    onMounted(async () => {
        let foundInCache = checkForProductInCache()

        if (foundInCache) {
            return Object.assign(product, foundInCache)
        }
        try {
            let res = await baseAPI.get(
                `/collections/products/entries/${productId}`
            );

            if (res) {
                Object.assign(product, res.data)
                productCache.push(res.data)
                const dedupe = productCache.filter((v, i, a) => a.findIndex(v2 => ['id'].every(k => v2[k] === v[k])) === i)
                setStorageWithExpiry("product_cache", dedupe)
            }
        } catch (error) {
            console.log(error)
        }
    })

    return {
        product
    }
}

