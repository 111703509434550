<template>
    <div class="checkboxes-group">
        <slot name="before-input" />
        <ul
            class="checkbox-group__options mb-0 rounded overflow-hidden w-full flex flex-col font-bold"
            :class="[columns]"
        >
            <vue-collapsible-panel-group class="flex flex-col gap-3" accordion>
                <template v-for="option in options" :key="option.id">
                    <vue-collapsible-panel
                        v-if="typeof option === 'object'"
                        class=""
                        :expanded="false"
                    >
                        <template v-if="option.title" #title
                            ><span class="text-primary">{{
                                option.title
                            }}</span></template
                        >
                        <template v-if="option.terms" #content>
                            <template
                                v-for="child in option.terms"
                                :key="child.id"
                            >
                                <v-checkbox
                                    v-model="selected"
                                    @change="handleCheck"
                                    :option="child"
                                    :handle="handle"
                                />
                            </template>
                        </template>
                    </vue-collapsible-panel>
                </template>
            </vue-collapsible-panel-group>
        </ul>
        <slot name="after-input" />
    </div>
</template>

<script setup>
import { ref, onMounted, defineProps, defineEmits } from "vue";

const { handle, columns, options, old, groupSelect, clearAll } = defineProps({
    options: {
        type: [Object],
        required: false,
    },
    old: {
        type: [Array],
    },
    handle: {
        type: String,
        required: false,
        default: "",
    },
    collection: String,
    columns: {
        type: String,
        required: false,
        default: "",
    },
    groupSelect: {
        type: String,
        required: false,
    },
    clearAll: Boolean,
});

const emit = defineEmits([
    "input",
    "select-all",
    "select-some",
    "select-none",
    "clearFilters",
]);

// const collectionTitle = computed(() => {
//     return collection ? collection.replaceAll("_", " ") : null;
// });

let selected = ref([]);

// const optionsLength = computed(() => {
//     return Object.entries(options).length;
// });

const handleCheck = (e) => {
    emit("input", selected.value);

    if (selected.value.length === Object.entries(options)) {
        return emit("select-all");
    } else if (selected.value.length) {
        return emit("select-some");
    }

    return emit("select-none");
};

// const isParent = (option) => {
//     return option.includes("-parent");
// };

// const clearFilters = () => context.emit("clearFilters");

const selectAll = () => {
    if (typeof options === "object") {
        return (selected.value = Object.keys(options.value).filter(
            (el) => !el.includes("-parent")
        ));
    }
    selected.value = options.map((option) => option[0]);
};

const unSelectAll = () => {
    return (selected.value = []);
};

const handleGroupSelect = () => {
    if (groupSelect.value === "all") {
        selectAll();
    } else if (groupSelect.value === "none") {
        unSelectAll();
    } else {
        return;
    }

    handleCheck();
};

// watch(groupSelect, () => {
//     handleGroupSelect();
// });

// watch(clearAll, () => {
//     if (clearAll.value && selected.value.length) {
//         unSelectAll();
//         handleCheck();
//     }
// });

onMounted(() => {
    // console.log({ options });
    if (old?.value?.length || false) {
        selected.value = old.value;
        handleCheck();
    }
});
</script>

<style>
.custom-label input:checked + svg {
    display: block !important;
}
</style>
