<script setup>
import { ref } from "vue";
import { useProduct } from "../../composables/useProduct";
import { useCart } from "../../composables/useCart";

const props = defineProps({
    item: {
        type: Object,
        required: true,
    },
});

const { product } = useProduct(props?.item?.entryId);
const { removeFromCart } = useCart();
</script>
<style lang=""></style>

<template lang="">
    <div class="flex items-center gap-3">
        <a class="flex-grow" :href="product.permalink">
            {{ product.title }}
        </a>
        <input
            class="text-black border flex-shrink-0 border-lightgray w-10 text-center py-0 px-2"
            type="text"
            v-model="item.quantity"
        />
        <i
            class="fas fa-times-circle ml-1 cursor-pointer"
            @click="removeFromCart(props?.item?.entryId)"
        ></i>
    </div>
</template>
