export class responsiveCustomPropHeight {
  constructor(elements, prop, cb = null, padding = 0) {
    this.elements = elements;
    this.prop = prop;
    this.setCustomProp = this.setCustomProp.bind(this);
    this.padding = padding;
    this.init();
    if (cb) {
      this.cb = cb;
    }
  }

  init() {
    this.updateHeight();
    this.observe();
  }

  /** 
    Observe the height of all elements and update the custom prop
    @private
    @since 1.0.0
  */

  observe() {
    // Observe the height of all elements and update the custom prop
    const observer = new ResizeObserver(this.onChange.bind(this));
    this.elements.forEach((element) => {
      if (element) {
        observer.observe(element);
      }
    });
  }

  onChange() {
    // Update height
    this.updateHeight();

    // Set custom prop
    if (this?.prop) {
      this.setCustomProp();
    }

    // if callback is set, call it
    if (this?.cb) {
      this.cb(this.height);
    }
  }

  updateHeight() {
    this.height =
      this.elements?.reduce((acc, element) => {
        const height = element?.clientHeight ?? 0;
        return acc + height;
      }, 0) ?? null;
  }

  getHeight() {
    return this.height;
  }

  setCustomProp() {
    // Set css custom prop
    document.documentElement.style.setProperty(
      `--${this.prop}`,
      `calc(${this.height}px + ${this.padding})`
    );
  }
}
