<template>
    <toggle-simple v-model="value">
        <button
            class="btn px-2 w-full flex items-center justify-center text-center gap-2"
        >
            Add to Favourites
            <v-icon
                name="heart"
                class="flex-shrink-0 cursor-pointer"
                :class="[
                    value
                        ? 'text-primary stroke-primary'
                        : 'stroke-primary text-white',
                ]"
            />
        </button>
    </toggle-simple>
</template>

<script>
import { useFavourites } from "../../composables/useFavourites";
export default {
    name: "Favourites Button",
    props: {
        userId: {
            type: String,
            required: true,
        },
        entryId: {
            type: String,
            required: true,
        },
    },
    setup({ userId, entryId }) {
        const { value } = useFavourites(userId, entryId);

        return {
            value,
        };
    },
};
</script>

<style></style>
