import { ref, watch } from 'vue'
const currentStep = ref({ id: '', index: 0 })
const steps = ref([])

const useProcessNav = () => {

    watch(steps, () => {
        const index = steps.value.findIndex(el => el.permalink === window.location.href);
        const id = steps.value[index]?.id || '';
        currentStep.value = {
            index,
            id
        }
    })


    const nextStep = () => {
        if (currentStep.value.index === steps.value.length) return
        window.location.href = steps.value[currentStep.value.index + 1].permalink
    };

    const prevStep = () => {
        if (currentStep.value.index === 0) return
        window.location.href = steps.value[currentStep.value.index - 1].permalink
    };

    return {
        steps,
        nextStep,
        prevStep,
        currentStep
    }
}

export { useProcessNav }
