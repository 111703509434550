<template>
  <a :href="`https://www.google.com/maps/dir/?api=1&destination=${address}&travelmode=driving`" target="_blank"
    class="flex gap-2 items-center"><img class="max-h-5" :src="icon" /> {{ address }}</a>
</template>
<script setup>
  const props = defineProps({
    address: {
      type: String,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    }
  });
</script>