<script>
import { reactive } from "vue";
export default {
    setup(props, ctx) {
        const store = reactive({});
        const reveal = (haystack, needle) => {
            return store[haystack] === needle;
        };
        return () =>
            ctx.slots.default({
                store,
                reveal,
            });
    },
};
</script>
