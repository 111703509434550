<template>
    <div class="process-chart-navigation">
        <button @click="prevStep">Previous Step</button>
        <button @click="nextStep">Next Step</button>
    </div>
</template>

<script setup>
import { useProcessNav } from "../../composables/useProcessNav";

const { nextStep, prevStep } = useProcessNav();
</script>

<style></style>
