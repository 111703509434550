<script setup>
import { useFilter } from "../../composables/useFilter";
import { contentAPI } from "../../services/contentAPI";

const {
    filter,
    collection,
    baseFilters,
    headerIcons,
    inline,
    filterControlClass,
} = defineProps({
    filter: Object,
    index: Number,
    collection: String,
    inline: Boolean,
    filterControlClass: String,
    headerIcons: {
        type: Boolean,
        default: true,
    },
    baseFilters: {
        type: Object,
        default: () => ({}),
    },
});

const { handleFilter, fetchResults, clearFilters, url } = useFilter(contentAPI, {
    collection,
    param: filter?.filter_by?.value || "taxonomies",
    condition: false,
    baseFilters,
});

const filterChange = (e) => {
    
    let value = Array.isArray(e)
        ? e.map((item) => item.includes(":") && item.split(":").pop())
        : e;

    handleFilter({
        term: filter?.override_url_endpoint || filter.id,
        filter: value,
    });

    console.log({term: filter?.override_url_endpoint || filter.id, filter: value})
};

</script>

<style></style>

<template>
    <div>
        <component
            :is="`${filter.filter_by.value}-group`"
            :class="[inline ? 'flex gap-4' : '', filterControlClass]"
            :url="url"
            :headerIcons="headerIcons"
            @clearFilters="clearFilters()"
            @filter-change="filterChange($event)"
            :collection="collection"
            :filter="filter"
            :expanded="true"
            :index="index"
        />
    </div>
</template>
