import { ref, reactive, computed, onMounted, nextTick } from 'vue'
import { useUrlSearchParams } from '@vueuse/core'
import eventBus from '../services/eventBus'

let url = useUrlSearchParams('history');

const results = reactive({
    data: ref([]),
    meta: {}
});

let loading = ref(false);

const searchQuery = ref(url.q);

const useFilter = (api, options = {}) => {
    let localURL = useUrlSearchParams('history');
    options = {
        collection: 'activities',
        param: 'taxonomies',
        condition: false,
        baseFilters: {},
        ...options
    }

  const activeFilterCount = computed(() => Object.keys(url).reduce((a, b) => {

    if (options.baseFilters[b]) return a

    if (String(url[b]).includes('|')) {
      return a + url[b].split('|').length
    }

    return b !== 'page' ? a + 1 : a
  }, 0));

    onMounted(async () => {
        if (Object.keys(options.baseFilters).length !== 0) {
            Object.keys(options.baseFilters).forEach(key => {
                url[key] = Array.isArray(options.baseFilters[key]) ? options.baseFilters[key].join("|") : options.baseFilters[key];
            })
            await nextTick();
            fetchResults()
        }
    })

    const clearFilters = () => {
      const params = Object.keys(url).forEach(key => {
            if (!options?.baseFilters) return delete url[key]

            if (options?.baseFilters[key]) {
                url[key] = options.baseFilters[key]
            } else {
                delete url[key]
            }
        })

      eventBus.$emit('clearFilters')

        searchQuery.value = '';

      nextTick(() => {
        fetchResults();
      })
    };

    const handleSearch = (search) => {
        if (search) {
            url.q = search;
        } else {
            delete url.q;
        }
        searchQuery.value = search;
    }

    const handleFilter = ({
        term = "",
        filter = null,
    } = {}) => {
        delete url.page

      if (filter && typeof filter === 'object') {
        // filter contains min and max values
        // set URL to be `${options.param}[${term}${!!options.condition ? `:${options.condition}` : ''}][min]`
        // set URL to be `${options.param}[${term}${!!options.condition ? `:${options.condition}` : ''}][max]`
        // filter.min can be 0, we need to check if it's not undefined

        if (Object.hasOwnProperty.call(filter, 'min')) {
          url[`${options.param}[${term}${!!options.condition ? `:${options.condition}` : ''}][min]`] = filter.min
        }

        if (Object.hasOwnProperty.call(filter, 'max')) {
          url[`${options.param}[${term}${!!options.condition ? `:${options.condition}` : ''}][max]`] = filter.max
        }

        if (!filter.min && !filter.max) {
          delete url[`${options.param}[${term}${!!options.condition ? `:${options.condition}` : ''}][min]`]
          delete url[`${options.param}[${term}${!!options.condition ? `:${options.condition}` : ''}][max]`]
        }
      }

      filter
        ? url[`${options.param}[${term}${!!options.condition ? `:${options.condition}` : ''}]`] = Array.isArray(filter) ? filter.join("|") : filter
        : delete url[`${options.param}[${term}${!!options.condition ? `:${options.condition}` : ''}]`];

      // if value is empty delete it from url
      if (url[`${options.param}[${term}${!!options.condition ? `:${options.condition}` : ''}]`] === "") {
        delete url[`${options.param}[${term}${!!options.condition ? `:${options.condition}` : ''}]`];
      }

        nextTick(() => {
            fetchResults();
        })
    };

    // Anytime our fitler URL changes, we will do a fetch
    // watch(url, (val) => {
    //     nextTick(() => {
    //         fetchResults();
    //     })
    // });

    const fetchResults = async () => {
      loading.value = true;
        try {
            let res = await api.get(
                `/collections/${options.collection}/entries${decodeURIComponent(
                    location.search
                )}`
            );
            // console.log(`/collections/${options.collection}/entries${decodeURIComponent(
            //     location.search
            // )}`)
            if (res) {
                results.data = res.data;
                results.meta = res.meta
            }
        } catch (error) {
            console.log(error)
        }
    loading.value = false;
  };

  const fetchMore = async (page) => {
    const params = new URLSearchParams(location.search);
    params.set('page', page);
    loading.value = true;
    try {
      let res = await api.get(
        `/collections/${options.collection}/entries${decodeURIComponent(
          '?' + params.toString()
        )}`
      );
      if (res) {
        results.data.push(...res.data);
        results.meta = res.meta
      }
    } catch (error) {
      console.log(error)
    }
    loading.value = false;
  };

    return {
      loading,
        fetchResults,
      fetchMore,
        results,
        url,
        handleFilter,
        handleSearch,
        searchQuery,
        clearFilters,
        activeFilterCount
    }
}

export { useFilter, searchQuery, results, url }
