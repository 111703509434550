<template>
    <div class="card card__taxonomy relative overflow-hidden flex-1">
        <div class="bg-lightgray mb-6">
            <a class="flex overflow-hidden" :href="permalink">
                <img
                    v-if="image.url"
                    class="bg-lightgray mix-blend-multiply card-thumb object-cover transition-transform duration-500 hover:scale-110"
                    :alt="title"
                    :src="image.url"
                />
                <!-- <img
                    v-if="productShotURL"
                    class="w-1/2 card-thumb object-cover mix-blend-multiply"
                    :alt="title"
                    :src="productShotURL"
                /> -->
            </a>
        </div>
        <a :href="permalink">
            <h4
                class="card-link__text font-bold text-primary"
                :style="{ color }"
            >
                {{ title }}
            </h4>
        </a>
        <div v-if="contentTruncated" class="card-body pb-6">
            <span v-html="contentTruncated" />
        </div>
        <a class="btn" :href="permalink"> View Products</a>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { cardProps, useCard } from "../../composables/useCard";
import { truncateWords } from "../../functions/helpers";

const { entry, color, thumbnail, hasThumbnail } = defineProps({
    ...cardProps,
    color: String,
});

const { title, content, image, permalink, ID } = useCard(entry, thumbnail);

const contentTruncated = computed(() => {
    return truncateWords(content, 20);
});

const productShotURL = computed(() => {
    return entry?.product_shot && `/img/${entry?.product_shot?.id}?w=500&h=500`;
});
</script>
