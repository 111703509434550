<template>
    <div class="card card__project overflow-hidden flex-1">
        <div class="card__content bg-lightgray relative mb-4">
            <a v-if="image?.url" :href="permalink" class="block card-image overflow-hidden h-100">
                <img
                    loading="lazy"
                    class="w-full object-cover transition-transform duration-500 hover:scale-110"
                    :alt="title"
                    :src="image.url"
                />
            </a>
            <!-- <a
                class="absolute block w-full px-6 py-3 bg-black bg-opacity-60 card-link bottom-0 no-underline"
                :href="permalink"
            >
                <span
                    class="card__title flex items-center gap-2 font-bold text-white"
                >
                    <i class="fas fa-map-marker-alt text-secondary"></i>
                    {{ location || title }}
                </span>
            </a> -->
        </div>
        <div>
          <span class="card__location flex items-center gap-2 font-bold text-black">
              <i class="fas fa-map-marker-alt text-secondary"></i>
                {{ location }}
            </span>
        
          <a
              class="card-link text-xl no-underline"
              :href="permalink"
          >
              
              <h4 v-if="title" class="text-2xl text-current mb-0">{{ title }}</h4>
          </a>
        </div>
    </div>
</template>

<script setup>
import { cardProps, useCard } from "../../composables/useCard";

const { entry, thumbnail, classes } = defineProps({
    ...cardProps,
});

const { title, image, permalink, ID } = useCard(entry, thumbnail);
const location = entry?.location || null;
</script>

<style lang="scss">
#app .card__project {
  // container-type: size;
}

@container (min-height: 289px) {
    #app .card-image {
      max-height: none;
    }
  }
</style>
