import { createApp } from "vue";
const app = createApp({})

// Vue 3 composables from vueuse.org
import { vElementVisibility } from "@vueuse/components";
app.directive('element-visibility', vElementVisibility)

import IsIntersecting from "./directives/IsIntersecting";
app.directive('is-intersecting', IsIntersecting)


// Google Map Component
import VueGoogleMaps from '@fawmi/vue-google-maps'
app.use(VueGoogleMaps, {
    load: {
        key: 'AIzaSyDdSQR50QDiNlCyFr7M6U-ZniCe346KJ1U',
    },
  autobindAllEvents: true
})

// tooltips
import Popper from 'vue3-popper'
app.use(Popper)



import VueCollapsiblePanel from '@dafcoe/vue-collapsible-panel'
// import '@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css'
app.use(VueCollapsiblePanel);

// Tabs
import HmwTabs from './components/tabs/hmw-tabs';
import HmwTabsHeaderTabs from './components/tabs/hmw-tabs-header-tabs';
import HmwTabsContentPanels from './components/tabs/hmw-tabs-content-panels';
import HmwTabHeader from './components/tabs/hmw-tab-header';
import HmwTabContent from './components/tabs/hmw-tab-content';
app.component('hmw-tabs', HmwTabs);
app.component('hmw-tabs-header-tabs', HmwTabsHeaderTabs);
app.component('hmw-tabs-content-panels', HmwTabsContentPanels);
app.component('hmw-tab-header', HmwTabHeader);
app.component('hmw-tab-content', HmwTabContent);

import Navbar from './directives/Navbar'
app.directive('navbar', Navbar)

// Range Slider 
import VueformSlider from '@vueform/slider'
import "@vueform/slider/themes/default.css";
app.component('Slider', VueformSlider)

// Youtube Iframe API
import YoutubeIframeAPI from "./components/videos/YoutubeIframeAPI";
app.component('youtube', YoutubeIframeAPI);

// We can parse markdown with the v-md directive
import VueMarkdown from './directives/Markdown'
app.directive('md', VueMarkdown)

// This will trigger a callback if we have clicked outside of the element v-click-outside is on
import ClickOutside from './directives/ClickOutside'
app.directive('click-outside', ClickOutside)

import scrollToClick from "./directives/ScrollToClick";
app.directive('scroll-to-click', scrollToClick)

import StickyTop from "./directives/StickyTop";
app.directive('sticky-top', StickyTop)

import isBelowScroll from "./directives/IsBelowScroll";
app.directive('is-below-scroll', isBelowScroll)

// Whenever this element exists on page (or on load), it will scroll to that element
import ScrollTo from './directives/ScrollTo'
app.directive('scroll-to', ScrollTo)

// Make the header hide/show based on scroll direction (mobile)
import ToggleOnScroll from './directives/ToggleOnScroll.js';
app.directive('toggle-on-scroll', ToggleOnScroll)

// Find any links inside the text and URLify them
import URLifyText from './directives/URLifyText.js';
app.directive('urlify-text', URLifyText)

// Custom modal / dialog component based on native browser API
import OpenDialog from './directives/OpenDialog.js';
app.directive('open-dialog', OpenDialog)

// Laravel SVG Vue
import SvgVue from 'svg-vue3';
app.use(SvgVue);

import { responsiveCustomPropHeight } from './functions/responsiveCustomPropHeight';


// This is for implementing modals, toasts, or info toggles
// import Popper from "vue3-popper";
// app.component('Popper', Popper)

// Lightweight scroll animation library
// import sal from 'sal.js';
// import 'sal.js/dist/sal.css';

// require('./functions/globals');

// import 'eva-icons/style/eva-icons.css'
// app.config.compilerOptions.isCustomElement = tag => tag.startsWith('x-')

// Glob and register all of the components
const filesRecursive = require.context('./components', true, /\.vue$/i)
filesRecursive.keys().map(key => {
    let name = key.split('/').pop().split('.')[0];
    app.component(name, filesRecursive(key).default)
})

// Social share icons
import VueSocialSharing from 'vue-social-sharing'
app.use(VueSocialSharing);

app.mount("#app")

import GLightbox from 'glightbox';
import 'glightbox/dist/css/glightbox.min.css';

window.addEventListener('load', () => {
    const lightbox = GLightbox({
        selector: ".glightbox"
    });
  new responsiveCustomPropHeight([document.querySelector("#main-header"), document.querySelector('#child-category-navigation')], "product-cat-header-height", null, '4rem');
  new responsiveCustomPropHeight([document.querySelector("#main-header"), document.querySelector('#map-header'), document.querySelector(".top-header")], "project-map-header-height", null, '0rem');
  new responsiveCustomPropHeight([document.querySelector("#main-header")], "main-header-height", null, '0rem');
})

