<template>
    <div
        class="flex gap-[0.6em]"
        :class="[attrs['class'] || 'items-center']"
        :style="[attrs['style'] || '']"
    >
        <svg v-if="iconExists" :fill="fill" class="icon-svg" :class="[svgClass]">
            <use :xlink:href="`#${name}`" />
        </svg>
        <slot v-else name="fallback"></slot>
        <slot />
    </div>
</template>

<script>
export default {
    props: {
        name: {
            type: String,
            required: true,
        },
        svgClass: {
            type: String,
            default: "",
        },
        fill: String,
    },
    inheritAttrs: false,
    setup({ name, fill }, { slots, attrs }) {
        const hasChildren = slots.default && slots.default();
        const iconExists = document.getElementById(name);
        return {
            name,
            iconExists,
            attrs,
            fill,
            hasChildren,
        };
    },
};
</script>
<style>
.icon-svg {
    fill: currentColor;
    width: 1em;
    height: 1em;
}
</style>
