<template>
  <dialog class="popup" ref="dialog">
    <div class="flex flex-col gap-4 p-4">
      <slot name="close">
        <i @click="closeDialog" class="fas fa-times"></i>
      </slot>
      <slot />
    </div>
  </dialog>
</template>
<script setup>
  import { ref, computed, onMounted } from 'vue';
  import { useCookies } from '@vueuse/integrations/useCookies'

  const props = defineProps({
    cookieName: {
      type: String,
      required: true,
    },
    cookieExpires: {
      type: String,
      default: 'never',
    },
    trigger: {
      type: String,
      required: true,
    },
    triggerDelay: {
      type: Number,
      default: 0,
    },
    triggerElement: String,
  })


  const triggerDelayInMS = computed(() => {
    return props.triggerDelay * 1000;
  })

  const dialog = ref(null);

  const openDialog = () => {
    if (checkCookie()) return;
    dialog.value.showModal();
  }

  const closeDialog = () => {
    dialog.value.close();

    if (props.cookieName && props.cookieExpires !== 'never') {
      setCookie();
    }
  }

  const cookies = useCookies([props.cookieName])

  const setCookie = () => {
    cookies.set(props.cookieName, true, {
      expires: new Date(props.cookieExpires),
    })
  }

  const checkCookie = () => {
    if (props.cookieName && props.cookieExpires !== 'never') {
      if (cookies.get(props.cookieName)) {
        return true;
      }
    }
    return false;
  }

  const handleLoad = () => {
    if (triggerDelayInMS.value) {
      setTimeout(() => {
        openDialog();
      }, props.triggerDelay * 1000);
    } else {
      openDialog();
    }
  }

  const handleExit = () => {
    // Watch for mouse to travel outside of the viewport
    document.addEventListener('mouseleave', (e) => {
      if (e.clientY <= 0) {
        if (triggerDelayInMS.value) {
          setTimeout(() => {
            openDialog();
          }, triggerDelayInMS.value);
        } else {
          openDialog();
        }
      }
    })
  }
  
  const handleClick = () => {
    if (props.triggerElement) {
      const trigger = document.querySelector(props.triggerElement);
      trigger?.addEventListener('click', () => {
        if (triggerDelayInMS.value) {
          setTimeout(() => {
            openDialog();
          }, triggerDelayInMS.value);
        } else {
          openDialog();
        }
      })
    }
  }

  onMounted(() => {
    switch(props.trigger) {
      case 'exit':
        handleExit();
        break;
      case 'click':
        handleClick();
        break;
      default:
        handleLoad();
    }
  })
</script>
<style lang="scss">
  .popup {
    width: 60vw;
  }

  .popup-content {
    display: block !important;
  }
</style>