<template>
    <div class="font-bold" v-if="currentColour?.title">
      {{ currentColour?.title || currentColour }}
    </div>
</template>

<script setup>
import { currentColour } from "../../composables/useProductColour";
</script>

<style></style>
