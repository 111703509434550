<template>
    <div
        :class="classList.container"
        :tabindex="disabled ? -1 : 0"
        :aria-checked="checked"
        :aria-describedby="describedby"
        :aria-labelledby="labelledby"
        role="switch"
        @keyup.space.prevent="handleSpace"
    >
        <div>
            <label class="block" @click="handleClick">
                <slot />
            </label>
        </div>
    </div>
</template>

<script>
import useValue from "../../composables/toggle/useValue";
import useToggle from "../../composables/toggle/useToggle";
import useClasses from "../../composables/toggle/useClasses";
import useKeyboard from "../../composables/toggle/useKeyboard";
/* istanbul ignore next */
const valueProps = {
    value: {
        validator: function (p) {
            return (p) =>
                ["number", "string", "boolean"].indexOf(typeof p) !== -1 ||
                p === null ||
                p === undefined;
        },
        required: false,
    },
    modelValue: {
        validator: function (p) {
            return (p) =>
                ["number", "string", "boolean"].indexOf(typeof p) !== -1 ||
                p === null ||
                p === undefined;
        },
        required: false,
    },
};
export default {
    name: "Toggle",
    emits: ["input", "update:modelValue", "change"],
    props: {
        ...valueProps,
        id: {
            type: [String, Number],
            required: false,
            default: "toggle",
        },
        name: {
            type: [String, Number],
            required: false,
            default: "toggle",
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false,
        },
        required: {
            type: Boolean,
            required: false,
            default: false,
        },
        falseValue: {
            type: [String, Number, Boolean],
            required: false,
            default: false,
        },
        middleValue: {
            type: [String, Number, Boolean],
            required: false,
            default: "",
        },
        trueValue: {
            type: [String, Number, Boolean],
            required: false,
            default: true,
        },
        classes: {
            type: Object,
            required: false,
            default: () => ({}),
        },
        labelledby: {
            type: String,
            required: false,
        },
        describedby: {
            type: String,
            required: false,
        },
    },
    setup(props, context) {
        const value = useValue(props, context);
        const toggle = useToggle(props, context, {
            checked: value.checked,
            update: value.update,
        });
        const classes = useClasses(props, context, {
            checked: value.checked,
        });
        const keyboard = useKeyboard(props, context, {
            check: value.check,
            uncheck: value.uncheck,
            checked: value.checked,
        });
        return {
            ...value,
            ...classes,
            ...toggle,
            ...keyboard,
        };
    },
};
</script>

<style scoped>
:root {
    --toggle-bg-on: red;
    --toggle-border-on: red;
}
</style>

<style></style>
