<template>
    <div>
        <div
            @click.stop="toggleMobileMenu"
            class="hamburger"
            :class="{ open: mobileNavOpened }"
        >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
        </div>
        <slot v-if="mobileNavOpened" />
    </div>
</template>

<script setup>
import { useHamburger } from "../../composables/useHamburger";
const { toggleMobileMenu, mobileNavOpened } = useHamburger();
</script>
