<template>
    <div class="flex relative flex-col gap-2 p-2">
        <h3 class="m-0">
          <span v-if="m.type !== 'full'">{{ m.title }}</span>
          <a v-else :href="m.permalink">{{ m.title }}</a>
        </h3>
        <directions-link v-if="m.address" :address="m.address" :icon="m.icon" />
        
        <Suspense v-if="init">
          <project-gallery :m="m" />
          <template #fallback>
              <v-loading-spinner />
          </template>
      </Suspense>
        <a target="_blank" class="btn btn--primary hover:bg-white absolute bottom-8 left-0 right-0 mx-auto z-10" v-if="m.type === 'full'" :href="m.permalink"
                >View full case study</a
            >
    </div>
</template>
<script setup>
 const props = defineProps({
    m: {
        type: Object,
        required: true,
    },
    init: {
        type: Boolean,
        required: true,
    }
 })
</script>
<style lang=""></style>
