export const getStorageWithExpiry = (key) => {
    const storage = localStorage.getItem(key);
    if (storage) {
        const storageObj = JSON.parse(storage);
        if (storageObj.expiry && storageObj.expiry < Date.now()) {
            localStorage.removeItem(key);
            return null;
        }
        return storageObj.value;
    }
    return null;
}

export const setStorageWithExpiry = (key, value, hours = 1) => {
    localStorage.setItem(key, JSON.stringify({
        value,
        expiry: Date.now() + (1000 * 60 * 60 * hours)
    }));
}
