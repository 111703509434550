import { baseAPI } from "../services/baseAPI"

export const fetchTerm = async (term) => {
  let res;

  try {
    let req = await baseAPI.get(`/taxonomies/colour_options/terms/${term}`)
    res = await req.data
  } catch (err) {
    if (err.request) {
      let errors = JSON.parse(err.request.response)
      res = errors
    }
  }

  return res
}
