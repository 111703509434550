<script>
import { ref } from "vue";
export default {
    setup(props, ctx) {
        const isToggled = ref(false);
        return () =>
            ctx.slots.default({
                isToggled,
            });
    },
};
</script>
