<template>
    <div class="process-chart flex flex-col gap-6" v-if="steps.length">
        <ul
            v-if="!journeyOpen"
            class="hidden lg:flex steps-compact font-heading list-none py-4 flex-wrap text-sm font-bold m-0"
            style="font-family: var(--font-headings)"
        >
            <li
                v-for="(step, i) in steps"
                :key="step.id"
                class="relative flex-1"
            >
                <a
                    class="flex group flex-col font-bold text-copycolor items-center"
                    :href="step.permalink"
                >
                    <div
                        class="transition group-hover:bg-primary group-hover:scale-125 group-hover:text-white cursor-pointer border-2 border-primary text-base rounded-full flex justify-center items-center w-10 h-10 mb-4"
                        :class="[
                            currentStep?.id === step.id
                                ? 'bg-primary text-white scale-125'
                                : 'bg-lightgray text-primary',
                        ]"
                    >
                        0{{ i + 1 }}
                    </div>
                    <span
                        class="text-primary"
                        style="color: rgb(var(--color-primary))"
                    >
                        {{ step.title }}
                    </span>
                </a>
            </li>
            <li class="relative flex-1">
                <div class="flex group flex-col text-copycolor items-center">
                    <div
                        class="text-base flex justify-center items-center w-10 h-10 mb-4"
                    >
                        09
                        <img
                            class="absolute transition -top-7 w-20 h-20 group-hover:scale-125"
                            src="/assets/icons/play-rocket-icon-01.svg"
                        />
                    </div>
                    <span
                        class="text-primary"
                        style="color: rgb(var(--color-primary))"
                    >
                        Play!
                    </span>
                </div>
            </li>
        </ul>
        <template v-if="!compact">
            <a
                v-if="!journeyOpen"
                @click.prevent="toggleJourney"
                href="#"
                class="hidden lg:inline-flex gap-2 mx-auto items-center"
                ><i
                    class="fas"
                    :class="[
                        journeyOpen ? 'fa-minus-circle' : 'fa-plus-circle',
                    ]"
                >
                </i>
                Expand Play Journey</a
            >
            <div
                ref="animationWrapper"
                class="relative"
                v-if="journeyOpen || !isLargeScreen"
            >
                <v-clouds :count="journeyOpen ? 10 : 2" />
                <component
                    :is="isLargeScreen ? 'div' : 'generic-slider'"
                    :id="isLargeScreen ? 'play_journey' : ''"
                    :loop="false"
                    @slideChange="hideHint"
                >
                    <div
                        class="play_journey-item group w-full"
                        v-for="(step, i) in steps"
                        :key="step.id"
                    >
                        <div class="play_journey-content text-base">
                            <div
                                class="animation-container flex flex-col items-center z-10"
                            >
                                <div
                                    v-if="i + 1 <= steps.length"
                                    class="flex flex-col items-center h-full justify-center"
                                >
                                    <span
                                        class="text-secondary text-sm font-bold"
                                        >{{ i + 1 }}</span
                                    >
                                    <a :href="step.permalink">
                                        <h3 class="uppercase text-primary">
                                            {{ step.title }}
                                        </h3>
                                    </a>
                                </div>
                                <a :href="step.permalink">
                                    <img
                                        class="relative bg-white w-24 h-24 z-10 rounded-full"
                                        v-if="step?.path_icon"
                                        :src="step.path_icon.permalink"
                                    />
                                </a>
                                <p
                                    v-if="step.content"
                                    class="leading-relaxed flex-grow m-0 py-4 text-center text-copycolor max-w-[25ch] self-center justify-self-center lg:mx-0"
                                    v-html="
                                        truncateWords(step.content, 12, '...')
                                    "
                                />
                                <a
                                    v-if="i + 1 < steps.length"
                                    class="text-primary font-bold"
                                    :href="step.permalink"
                                    >Learn More</a
                                >
                            </div>
                        </div>
                    </div>
                    <div class="play_journey-item group w-full">
                        <div
                            class="play_journey-content flex flex-col h-full items-center text-base"
                        >
                            <div
                                class="flex flex-col items-center justify-center"
                                :class="[{ 'pb-2' : !isLargeScreen }]"
                            >
                                <span class="text-secondary text-sm font-bold"
                                    >09</span
                                >
                                <span>
                                    <h3 class="uppercase text-primary">Play</h3>
                                </span>
                            </div>
                            <span>
                                <svg
                                    class="relative z-10 transition group-hover:scale-125"
                                    :class="[{ '-top-8 w-36 h-36': isLargeScreen}, { 'w-48 h-48': !isLargeScreen }]"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 1133.86 1133.86"
                                >
                                    <path
                                        ref="rocketBg"
                                        fill="#ffcb05"
                                        d="M566.93,1061.61c207.45,0,375.61-168.17,375.61-375.61s-168.16-375.62-375.61-375.62S191.32,478.55,191.32,686s168.16,375.61,375.61,375.61"
                                    />

                                    <g ref="rocket">
                                        <path
                                            fill="#0066b3"
                                            d="M675.87,641.1c-1.78,7.21-9.08,11.59-16.29,9.78-7.19-1.8-11.58-9.09-9.78-16.28,1.79-7.22,9.09-11.6,16.29-9.8,7.21,1.79,11.58,9.09,9.78,16.29"
                                        />
                                        <path
                                            fill="#0066b3"
                                            d="M642.91,702.14l-149.14-37.19-26.05,48.9,175.23,43.69-.03-55.4Zm149.14-82.61l-35.9-78.22-37.88,151.91,42.41,52.13,31.37-125.82Zm-25.57-230.75c9.87-39.63,9.16-86.38-2.08-139.02l-165.55-41.27c-34.63,41.2-57.21,82.14-67.08,121.77l-61.28,245.77,143.43,35.76c7.19,1.8,11.58,9.1,9.78,16.29-1.79,7.21-9.09,11.59-16.29,9.8l-143.42-35.76-6.51,26.09,234.71,58.52,47.14-189.08,27.15-108.87Zm-147.38-202.95l138.07,34.42c-14.22-51.33-34.28-94.43-44.66-114.99-18.82,13.28-56.77,41.91-93.41,80.56m-249.61,461.97l61.92-26.11,37.86-151.9-68.41,52.2-31.37,125.81Zm66.41,68.6l30.98-58.16-28.94-7.21c-1.27-.32-2.45-.82-3.52-1.45l-79.25,33.43c-10.26,4.33-20.92-4.96-18.26-15.63l39.02-156.47c.73-2.97,2.46-5.58,4.88-7.44l98.87-75.43,26.01-104.29c11.42-45.79,37.73-92.61,78.21-139.14,57.75-66.41,123.53-108.51,126.3-110.27,3.11-1.97,6.88-2.59,10.46-1.69,3.56,.89,6.61,3.18,8.43,6.4,1.62,2.86,39.93,70.91,59.76,156.66,13.88,60.09,15.13,113.78,3.72,159.57l-26,104.28,51.89,113.04c1.27,2.78,1.57,5.9,.83,8.85l-39.02,156.47c-1.23,4.95-5.15,8.77-10.12,9.86-4.97,1.12-10.14-.68-13.35-4.63l-54.28-66.72c-3.44,.13-2.9-.35-32.73-7.6l.06,65.9c0,4.14-1.9,8.05-5.17,10.59-3.25,2.55-7.52,3.45-11.52,2.46l-208.65-52.02c-8.43-2.1-12.74-11.6-8.61-19.36"
                                        />
                                        <path
                                            fill="#0066b3"
                                            d="M688.28,424.68c7.17-28.74-10.39-57.97-39.15-65.16-28.75-7.16-58,10.4-65.16,39.16-7.19,28.82,10.3,57.97,39.15,65.16,28.82,7.19,57.97-10.32,65.16-39.16m-130.39-32.51c10.76-43.13,54.6-69.47,97.75-58.73,43.13,10.77,69.47,54.61,58.73,97.74-10.78,43.22-54.5,69.53-97.74,58.73-43.24-10.77-69.52-54.49-58.74-97.74"
                                        />
                                        <g ref="rocketTrails">
                                            <path
                                                fill="#0066b3"
                                                d="M481.78,772.75c7.21,1.79,11.59,9.09,9.8,16.29l-28.39,113.85c-1.79,7.21-9.09,11.59-16.29,9.8-7.21-1.79-11.58-9.09-9.8-16.29l28.39-113.86c1.8-7.19,9.1-11.58,16.29-9.78"
                                            />
                                            <path
                                                fill="#0066b3"
                                                d="M612.67,819.24l-28.39,113.85c-1.79,7.21-9.09,11.59-16.29,9.8-7.19-1.8-11.58-9.09-9.78-16.29l28.39-113.86c1.79-7.19,9.09-11.58,16.28-9.78,7.21,1.79,11.59,9.09,9.8,16.29"
                                            />
                                            <path
                                                fill="#0066b3"
                                                d="M551.28,807.53l-48.81,195.78c-1.8,7.21-9.1,11.59-16.29,9.78-7.21-1.79-11.59-9.09-9.8-16.28l48.82-195.79c1.79-7.19,9.09-11.58,16.28-9.78,7.21,1.79,11.59,9.09,9.8,16.29"
                                            />
                                        </g>
                                    </g>
                                </svg>
                            </span>
                        </div>
                    </div>
                </component>
                <!-- <div
                    v-show="!isLargeScreen"
                    class="flex mt-4 bg-lightgray pointer-events-none px-2 items-center transition-all justify-between gap-2"
                    :class="[hintVisible ? 'animate-pulse' : 'opacity-0']"
                >
                    <i class="fas fa-chevron-left"></i>
                    <span class="font-bold">Swipe or drag the items</span>
                    <i class="fas fa-chevron-right"></i>
                </div> -->
            </div>
            <a
                v-if="journeyOpen"
                @click.prevent="toggleJourney"
                href="#"
                class="hidden lg:inline-flex gap-2 mx-auto items-center"
                ><i
                    class="fas"
                    :class="[
                        journeyOpen ? 'fa-minus-circle' : 'fa-plus-circle',
                    ]"
                >
                </i>
                Collapse Play Journey</a
            >
        </template>
    </div>
</template>

<script setup>
import { baseAPI } from "../../services/baseAPI.js";
import { ref, onMounted, defineProps, nextTick, watch } from "vue";
import { truncateWords } from "../../functions/helpers";
import { useBreakpoints } from "../../composables/useBreakpoints";
import { useProcessNav } from "../../composables/useProcessNav";
import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);

const animationWrapper = ref(null);
const rocket = ref(null);
const rocketTrails = ref(null);
const timelines = ref([]);

function rocketAnimation(withScrollTrigger = true) {
    if (rocket.value) {
        const rocketAnimation = withScrollTrigger ? gsap.timeline({
            scrollTrigger: {
                trigger: rocket.value,
                start: "top 50%", 
                end: "bottom 20%", 
                scrub: false,
                markers: false, 
            },
        }) : gsap.timeline();

        timelines.value.push(rocketAnimation);

        gsap.set(rocket.value, { y: "+=80%", x: "-=50%", opacity: 0, transformOrigin: "bottom center" });

        rocketAnimation.to(
            rocket.value,
            {
                duration: 3 / 2,
                opacity: 1,
                x: "5%",
                y: "-8%",
                transformOrigin: "center top",
                ease: "power1.inOut",
            }
        );

        rocketAnimation.to(rocket.value, {
            duration: 4 / 2,
            x: "0",
            y: "0",
            rotation: 1,
            ease: "power1.inOut",
            yoyo: true,
            repeat: -1,
        });
        rocketAnimation.fromTo(
            rocketTrails.value.children,
            {
                opacity: 0.3,
            },
            {
                duration: 2 / 2,
                opacity: 1,
                transformOrigin: "center top",
                ease: "power1.inOut",
                stagger: 0.3,
                yoyo: true,
                repeat: -1,
            },
            0
        );
    }
}

function staggerAnimation() {
    let mm = gsap.matchMedia();
    const triggerElement = animationWrapper.value;
    const items = gsap.utils.toArray(".animation-container");
    const timeline = gsap.timeline();

    timelines.value.push(timeline);

    // this setup code only runs when viewport is at least 1024px wide

    // Use gsap and scrolltrigger to stagger in the items
    gsap.set(items, { y: "+=50%", opacity: 0 });

    timeline.to(items, {
        y: "0%",
        opacity: 1,
        duration: 0.5,
        stagger: 0.25,
        ease: "power2",
        scrollTrigger: {
            trigger: triggerElement,
            start: "top 60%",
            end: "bottom 40%",
            scrub: 0.5,
            // markers: true,
        },
    });
}

const { steps, currentStep } = useProcessNav();

const journeyOpen = ref(false);
const hintVisible = ref(true);

const props = defineProps({
    options: {
        type: Object,
        default: () => ({
            stepClass: "bg-white",
        }),
    },
    compact: {
        type: Boolean,
        default: false,
    },
});

const isLargeScreen = useBreakpoints("lg");

// Everytime we go above/below the lg breakpoint, we cleanup all the animations as we re-trigger them when needed
// In different ways at differnet screen sizes.
watch(
    () => isLargeScreen.value,
    (val) => {
        journeyOpen.value = false;

        // loop over the timelines and kill them
        timelines.value.forEach((timeline) => {
            timeline.kill();
        });
    }
);

const toggleJourney = async () => {
    journeyOpen.value = !journeyOpen.value;

    await nextTick();

    if (journeyOpen.value) {
        ScrollTrigger.refresh();
        staggerAnimation();
        rocketAnimation();
    }
};

const hideHint = (swiper) => {
    if (hintVisible.value) {
        hintVisible.value = false;
    }
    const { activeIndex } = swiper;

    // Trigger the rocket animation on the last slide
    if (activeIndex === steps.value.length) {
        rocketAnimation(false);
    }
};

onMounted(async () => {
    try {
        let res = await baseAPI.get(
            "collections/process/entries?sort=order&status=published"
        );
        if (res.data) {
            steps.value = res.data;
        }
    } catch (error) {
        console.log(error);
    }
});
</script>

<style lang="scss">
.process-chart {
    .swiper-button-next::before,
    .swiper-button-prev::before {
        @apply text-primary shadow-none;
    }
}
@mixin right_cap() {
    &:before {
        width: 70%;
        left: 0;
    }
    &:after {
        content: "";
        border: var(--border-size) dotted rgb(var(--color-secondary));
        border-left: 0;
        border-radius: 0 30% 30% 0;
        position: absolute;
        right: 0;
        width: 100%;
        height: calc(100% + var(--gap) + var(--border-size));
    }
}

@mixin left_cap() {
    &:before {
        width: 80%;
        right: 0;
        left: auto;
    }
    &:after {
        content: "";
        border: var(--border-size) dotted rgb(var(--color-secondary));
        border-right: 0;
        border-radius: 30% 0 0 30%;
        position: absolute;
        left: 0;
        width: 100%;
        height: calc(100% + var(--gap) + var(--border-size));
    }
}

@mixin arrow($direction: right, $position: right) {
    &:before {
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        display: var(--fa-display, inline-block);
        font-style: normal;
        font-variant: normal;
        line-height: 1;
        text-rendering: auto;
        font-family: "Font Awesome 5 Free";
        font-weight: 900;
        content: "\f054";
        color: rgb(var(--color-primary));
        font-size: 2rem;
        position: absolute;
        top: 9.5rem;
        transform: translateY(-50%);
        z-index: 10;
        @if $direction == left {
            content: "\f053";
            right: auto;
        }
        #{$position}: 0;
    }
}

@mixin arrow_down($position: right) {
    &:before {
        color: rgb(var(--color-secondary));
        top: calc(100% - var(--gap) - var(--border-size));
        @if ($position == right) {
            transform: rotate(90deg) translate(-50%, -25%);
            right: 0;
            left: auto;
        } @else {
            transform: rotate(90deg) translate(-50%, 25%);
            left: 0;
            right: auto;
        }
    }
}

#play_journey {
    --border-size: 3px;
    --gap: 1rem;
    --content-padding: 2rem;
    transition: all 0.5s ease;
    position: relative;
    display: grid;
    grid: auto / repeat(6, 1fr);
    place-content: center;
    row-gap: var(--gap);
    .play_journey-item {
        grid-column: span 2;
        // animation: fadein 1s linear 1 forwards;
        // opacity: 0;
        position: relative;
        &:before,
        &:after {
            content: "";
            position: absolute;
            top: calc(9.5rem - var(--border-size) / 2);
            width: 100%;
        }

        &:before {
            border-top: var(--border-size) solid rgb(var(--color-primary));
            z-index: 1;
            left: 0;
        }

        &:after {
            z-index: -1;
        }

        @for $i from 1 to 10 {
            &:nth-child(#{$i}) {
                animation-delay: $i * 0.25s;
                order: #{$i};
            }
        }

        &:first-child {
            &:before {
                left: 0;
            }
            .play_journey-content {
                &:after {
                    position: absolute;
                    top: 9.5rem;
                    transform: translateY(-50%);
                    z-index: 10;
                    left: 0;
                    content: "";
                    width: 1rem;
                    height: 1rem;
                    border-radius: 50%;
                    background: rgb(var(--color-secondary));
                    border: 2px solid rgb(var(--color-primary));
                }
            }
        }

        &:nth-child(3) {
            @include right_cap();
            .play_journey-content {
                @include arrow_down();
            }
        }

        &:nth-child(4) {
            order: 5;
            .play_journey-content {
                @include arrow(left, left);
            }
            &:before {
                width: 80%;
            }
        }

        &:nth-child(5) {
            justify-content: flex-end;
            order: 4;
            .play_journey-content {
                @include arrow_down(left);
            }
            @include left_cap();
        }

        &:nth-child(4),
        &:nth-child(5) {
            display: flex;
            padding: 0 2rem;
            grid-column: span 3;
            &:after {
                width: 50%;
            }
        }

        &:nth-child(6) {
            &:before {
                width: 75%;
                right: 0;
                left: auto;
            }
        }

        &:nth-child(8) {
            @include right_cap();
            .play_journey-content {
                @include arrow_down();
            }
        }

        &:nth-child(9) {
            .play_journey-content {
                @include arrow(left, right);
            }
        }

        &:last-child {
            grid-column: 3 / -3;
            &:before {
                left: 50%;
                width: 120%;
            }
        }
    }

    .play_journey-content {
        transition: all 0.5s ease;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: var(--content-padding);
        @include arrow();
    }
}

.steps-compact {
    li {
        background-clip: content-box;
        a {
            @apply relative z-10;
        }
        &:first-child,
        &:last-child {
            &:before {
                width: 50%;
                right: 0;
                left: auto;
            }
        }
        &:last-child {
            &:before {
                left: 0;
            }
        }
        &:before {
            background-clip: content-box;
            content: "";
            left: 0;
            position: absolute;
            top: 25%;
            width: 100%;
            height: 2px;
            background-color: rgb(var(--color-primary));
        }
    }
}

@for $i from 1 to 10 {
    .cloud-speed-#{$i} {
        animation: moveclouds #{$i * 20.5}s linear infinite;
    }
}

@keyframes moveclouds {
    0% {
        transform: translateX(0);
    }
    100% {
        transform: translateX(-100vw);
    }
}

@keyframes fadein {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}
</style>
