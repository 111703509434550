<template>
  <h3 class="m-0">
    <span>{{ m . title }}</span>
  </h3>
  <directions-link v-if="m.address" :address="m.address" :icon="m.icon" />
  <Suspense v-if="init">
    <project-gallery v-if="m.type === 'gallery'" :m="m" />
    <template #fallback>
      <v-loading-spinner />
    </template>
  </Suspense>
</template>
<script setup>
  const props = defineProps({
    m: {
      type: Object,
      required: true,
    },
    init: {
      type: Boolean,
      required: true,
    }
  });
</script>
<style lang=""></style>
